import logo from './logo.svg';
import './App.css';
import { Header } from './Components/Header/Header';
import { NavbarTop } from './Components/Navbar/NavbarTop'
import { Home } from './Components/Home/Home';
import { FooterComponent } from './Components/FooterComponent/FooterComponent';
import { Route, Router, Routes, useLocation } from 'react-router-dom';
import { HeroCarouselSection } from './Components/HeroCarouselSection/HeroCarouselSection';
import { ContactForm } from './Components/ContactForm/ContactForm';
import { ContactForm2 } from './Components/ContactForm/ContactForm2';
import { CalendlyComponent } from './Components/CalenderComponent/CalendlyComponent';
import { LeanDataGovernance } from './Components/LDG/LeanDataGovernance';
import { Subscribe } from './Components/Subscription/Subscribe';
import { useEffect } from 'react';
import { AiLens } from './Components/AILens/AiLens';
import { DataQalityManagement } from './Components/DataQalityManagement/DataQalityManagement';
import { Form3 } from './Components/ContactForm/Form3';
import { ContactUs } from './Components/ContactForm/ContactUs';
import { PrivacyPolicy } from './Components/PrivacyPolicy/PrivacyPolicy';
import { ValueProposition } from './Components/ValuePropositions/ValueProposition';
import { StrategicAdvisory } from './Components/StrategicAdvisory/StrategicAdvisory';
import { GartnerComponent } from './Components/ExtraComponents/GartnerComponent';
import { LeanData } from './Components/LDG/LeanData';
import { IDQMPage } from './Components/DataQalityManagement/IDQMPage';
import DataQualityExcellence from './Components/DataQualityExcellence/DataQualityExcellence';
import { Corporate } from './Pages/Corporate';
import Leadership from './Pages/leadership/LeaderShip';
import ExtendedSupply from './Pages/ExtendedSupply/ExtendedSupply';
import IcontenFoundry from './Pages/icontentFoundy/IcontentFoundary';
import EnduringAssetLifecycleManagement from './Pages/EnduringAssetLifecycleManagemen/EnduringAssetLifecycleManagemen';
import IndustryandCustomers from './Pages/industriesandcustomers/IndustryandCustomers';
import ValueEngineer from './Pages/ValueEngineer/ValueEngineer';
import MegaMenu from './Components/megamenu/MegaMenu';
import { RaiseWithSAP } from './Components/SAP/RaiseWithSAP';
import { AboutUs } from './Pages/AboutPilog/AboutUs';
import { OilGas } from './Components/Indusries/OilGas';


function ScrollToTopOnNavigate() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <div className="App" style={{ overflowX: 'hidden' }}>
      <ScrollToTopOnNavigate />
      <header>
        {/* <Header/> */}
        {/* <NavbarTop/> */}
        <MegaMenu />
      </header>
      <main className="main-body">
        <Routes>
          <Route index path='/' element={<Home />} />
          <Route path="hero" element={<HeroCarouselSection />} />
          <Route path='contact-us' element={<ContactForm />} />
          <Route path='contact-us2' element={<ContactForm2 />} />
          <Route path='calendly' element={<CalendlyComponent />} />
          <Route path='lean-data-governance' element={<LeanData />} />
          <Route path='subscribe-now' element={<Subscribe />} />
          <Route path='ai-lens' element={<AiLens />} />
          <Route path='iDQM' element={<DataQalityManagement />} />
          {/* <Route path='iDQM' element = {<IDQMPage/>}/> */}
          <Route path='test-form' element={<Form3 />} />
          <Route path='Contact' element={<ContactUs />} />
          <Route path='privacy-policy' element={<PrivacyPolicy />} />
          <Route path='value-proposition' element={<ValueProposition />} />
          <Route path='strategic-advisory' element={<StrategicAdvisory />} />
          <Route path='gartner' element={<GartnerComponent />} />
          <Route path="data-excellence" element={<DataQualityExcellence />} />
          <Route path="corporate" element={<Corporate />} />
          <Route path="leader-ship" element={<Leadership />} />
          <Route path="extended-supply" element={<ExtendedSupply />} />
          <Route path="content-foundary" element={<IcontenFoundry />} />
          <Route path="enduring-asset" element={<EnduringAssetLifecycleManagement />} />
          <Route path="IndustryandCustomers" element={<IndustryandCustomers />} />
          <Route path="value-engineer" element={<ValueEngineer />} />
          <Route path="rise-with-sap" element={<RaiseWithSAP />} />
          <Route path='about-us' element={<AboutUs />} />
          <Route path="oil-and-gas-industries" element={<OilGas />} />

        </Routes>

        {/* <Home /> */}
      </main>
      <FooterComponent />



    </div>
  );
}

export default App;
