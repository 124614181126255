import {
  AddLocation,
  LinkedIn,
  LocationCity,
  Twitter,
} from "@mui/icons-material";
import React from "react";
import {
  Button,
  Carousel,
  Col,
  Container,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./AboutPiLog.css";
import Image1 from "../../Components/Assets/AboutPiLog/Dr.-Salomon-De-Jager_Pic.png";
import Image2 from "../../Components/Assets/AboutPiLog/Pieter-Strydom_Pic.png";
import Image3 from "../../Components/Assets/AboutPiLog/Gideon-Huisamen_Pic.png";
import Image4 from "../../Components/Assets/AboutPiLog/Dr.-Imad-Syed_Pic.png";
import Image5 from "../../Components/Assets/AboutPiLog/Wynand-Nortje_Pic.png";
import Image6 from "../../Components/Assets/AboutPiLog/Tracy-Bennett_Pic.png";
import Image7 from "../../Components/Assets/AboutPiLog/Reinhard-Schiel_Pic.png";
import Image8 from "../../Components/Assets/AboutPiLog/Johan-Zwarts_Pic.png";

export const AboutUs = () => {
  const slides = [
    {
      id: 1,
      title: "Dr. Salomon De Jager",
      position: "Chairman",
      description:
        "In 1995 Dr Salomon founded Pilog to become a leader in content creation and data governance. He participated in ISO TC184/SC4 since 2006 and initiated a South African mirror committee, SABS TC184 within the South African Bureau of Standards. ",
      linkText: "Read Case Study →",
      leader: Image1,
    },
    {
      id: 2,
      title: "Pieter Strydom",
      position: "Co - Chief Executive Officer",
      description:
        "Mr Strydom has a Mechanical Engineering degree from the University of Pretoria, South Africa, and furthered his studies with a MBL from Unisa. He started his career at Eskom as Maintenance Engineer in the nuclear field. ",
      linkText: "Read Case Study →",
      leader: Image2,
    },
    {
      id: 3,
      title: "Gideon Huisamen",
      position: "Co - Chief Executive Officer",
      description:
        "He worked as a Management and Cost accountant for 16 years in South Africa with a Telecom Company and also later in a food Canning Manufacturing Company.  ",
      linkText: "Read Case Study →",
      leader: Image3,
    },
    {
      id: 4,
      title: "Dr. Imad Syed",
      position: "Co - Chief Executive Officer & Chief Information Officer",
      description:
        "Senior Executive, Digital Advisory, Visionary Leader with vast experience in conceiving & designing business, enterprise information and data quality HUB strategies for medium to large enterprises ",
      linkText: "Read Case Study →",
      leader: Image4,
    },
    {
      id: 5,
      title: "Wynand Nortje",
      position: "Chief Technology Officer",
      description:
        "Mr Wynand Nortje obtained his Industrial Engineering degree from the University of Pretoria, South Africa and furthered his studies with a B.Sc Honours degree in Operations Research. ",
      linkText: "Read Case Study →",
      leader: Image5,
    },
    {
      id: 6,
      title: "Tracy Bennett",
      position: "Chief Operating Officer",
      description:
        "He was a former GEO-market CFO for operations and shared 500 services. Director for a Fortune 500 company and Global perspective with 17 years of international experience. ",
      linkText: "Read Case Study →",
      leader: Image6,
    },
    {
      id: 7,
      title: "Reinhard Schiel",
      position: "Chief Operating Officer",
      description:
        "He started his career as a telecommunications engineer in the laboratories of Telkom after completing his Electrotechnical Engineering degree at the University of Pretoria.  ",
      linkText: "Read Case Study →",
      leader: Image7,
    },
    {
      id: 8,
      title: "Johan Zwarts",
      position: "Chief Operating Officer",
      description:
        "Johan is the Chief Operating Officer and Senior Program Manager at PiLog Africa for various PiLog Clients involved in the world of Master Data Quality Management and related solutions.",
      linkText: "Read Case Study →",
      leader: Image8,
    },
  ];
  return (
    <div>
      <section className="about-pilog">
        <Container>
          <Row>
            <Col lg={7} className="about-ilog-left">
              <h1>About PiLog</h1>
              <p>
                PiLog is a global leader in digital transformation, specializing
                in AI-driven data quality, governance, and seamless ERP
                integration. With over 25 years of innovation, PiLog empowers
                enterprises with intelligent data solutions, ensuring
                structured, compliant, and optimized master data.
              </p>
            </Col>
            <Col lg={5}>
              <img
                loading="lazy"
                className="w-100"
                src={require("../../Components/Assets/AboutPiLog/About-PiLog_Image.png")}
                alt=""
              />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="pilog-fact-sheet">
        <Container className="fact-sheet-sec">
          <h2>Fact Sheet</h2>
          <p style={{ fontSize: "1.1rem" }}>
            A Snapshot of Excellence, Innovation, and Impact.
          </p>
          <Row>
            <Col lg={4}>
              <div className="pilog-fact-sheets">
                <h5>Awards</h5>
              </div>
            </Col>
            <Col lg={4}>
              <div className="pilog-fact-sheets">
                <h5>Partners</h5>
              </div>
            </Col>
            <Col lg={4}>
              <div className="pilog-fact-sheets">
                <h5>iContent Foundry</h5>
              </div>
            </Col>
            <Col lg={4}>
              <div className="pilog-fact-sheets">
                <h5>History</h5>
              </div>
            </Col>
            <Col lg={4}>
              <div className="pilog-fact-sheets">
                <h5>Customers</h5>
              </div>
            </Col>
            <Col lg={4}>
              <div className="pilog-fact-sheets">
                <h5>Add-Ons</h5>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="leading-companies">
        <div>
          <h2>Trusted by Industry Pioneers</h2>
          <p style={{ fontSize: "1.1rem" }}>
            A legacy of excellence, globally recognized and trusted by top
            organizations worldwide
          </p>
        </div>
        <div>
          <Marquee speed={50} delay={0}>
            <img
              loading="lazy"
              style={{ width: "70%" }}
              src="https://www.piloggroup.com/img/Client%20Logos/new/abu-dhabi-airport-logo.jpg"
              alt=""
            />
            <img
              loading="lazy"
              style={{ width: "70%" }}
              src="https://piloggroup.com/img/Client%20Logos/Aeronautical-Defence/Al_Maktoum_Airports.png"
              alt=""
            />
            {/* <img loading="lazy" style={{width: '10%'}} src="https://piloggroup.com/img/Client%20Logos/recent-logos/Dubai-airports.png" alt=''/> */}
            <img
              loading="lazy"
              style={{ width: "70%" }}
              src="https://www.piloggroup.com/img/Client%20Logos/new/riyadh-airport-logo.jpg"
              alt=""
            />
            <img
              loading="lazy"
              style={{ width: "70%" }}
              src="https://piloggroup.com/img/Client%20Logos/recent-logos/ESA.jpg"
              alt=""
            />
            <img
              loading="lazy"
              style={{ width: "70%" }}
              src="https://piloggroup.com/img/Client%20Logos/Cement/Aditya_MP_Birla%20Group.jpg"
              alt=""
            />
            <img
              loading="lazy"
              style={{ width: "70%" }}
              src="https://piloggroup.com/img/Client%20Logos/Cement/Birla_Cement.jpg"
              alt=""
            />
            <img
              loading="lazy"
              style={{ width: "70%" }}
              src="https://piloggroup.com/img/Client%20Logos/Cement/Birla_White_Cement.jpg"
              alt=""
            />
            <img
              loading="lazy"
              style={{ width: "70%" }}
              src="https://piloggroup.com/img/Client%20Logos/recent-logos/nuvoco-new-logo.png"
              alt=""
            />
            <img
              loading="lazy"
              style={{ width: "70%" }}
              src="https://piloggroup.com/img/Client%20Logos/recent-logos/ppc-cement.svg"
              alt=""
            />
            <img
              loading="lazy"
              style={{ width: "70%" }}
              src="https://piloggroup.com/img/Client%20Logos/Cement/UltraTech.png"
              alt=""
            />
            <img
              loading="lazy"
              style={{ width: "70%" }}
              src="https://piloggroup.com/img/Client%20Logos/new/logos-110.png"
              alt=""
            />
            <img
              loading="lazy"
              style={{ width: "70%" }}
              src="https://piloggroup.com/img/Client%20Logos/new/logos-106.png"
              alt=""
            />
            <img
              loading="lazy"
              style={{ width: "70%" }}
              src="https://piloggroup.com/img/Client%20Logos/Chemicals-Refineries/HZL.jpg"
              alt=""
            />
          </Marquee>

          <div className="d-flex justify-content-center align-items-center">
            <Link to="">
              <button className="partner-btn">Read More</button>
            </Link>
          </div>
        </div>
      </section>
      {/* <section className='our-partners'>
                <Container>
                    <Row>
                        <Col lg={6} className='p-3'>
                            <div>
                                <div className='d-flex align-items-center gap-4'>
                                    <img loading="lazy" style={{ width: '25%' }} src="https://piloggroup.com/img/partners/SAP_Silver_Partner_Logo.png" alt="" />
                                    <div>
                                        <h4>SAP Silver Partner</h4>
                                        <p>
                                            PiLog is a long standing partner with SAP, having done a large number of projects supporting SAP R/3, ECC, and MDM
                                        </p>
                                        <div className='line-div'></div>
                                        <Link to=''><h6>Learn More ⮕</h6></Link>
                                    </div>

                                </div>


                            </div>
                            <hr />
                            <div>
                                <div className='d-flex align-items-center gap-4'>
                                    <img loading="lazy" style={{ width: '25%' }} src="https://piloggroup.com/img/partners/PARTNERS-LOGOS-03.png" alt="" />
                                    <div>
                                        <h4>Siveco Group- COSWIN</h4>
                                        <p>
                                            From 1993 PiLog has been a partner with Siveco Group, a major international player and the European leader in
                                        </p>

                                        <div className='line-div'></div>
                                        <Link to=''><h6>Learn More ⮕</h6></Link>
                                    </div>

                                </div>

                            </div>
                        </Col>
                        <Col lg={6} className='p-3'>

                            <div>
                                <div className='d-flex align-items-center gap-4'>
                                    <img loading="lazy" style={{ width: '25%' }} src="https://piloggroup.com/img/partners/ORACLE_Partnernetwork_%20Logo.png" alt="" />
                                    <div>
                                        <h4>Oracle - Partner Network</h4>
                                        <p>
                                            PiLog has been an Oracle Golden partner since 2010 and has strategically decided to leverage and enhance our relationship with Oracle
                                        </p>

                                        <div className='line-div'></div>
                                        <Link to=''><h6>Learn More ⮕</h6></Link>
                                    </div>

                                </div>

                            </div>
                            <hr />
                            <div>
                                <div className='d-flex align-items-center gap-4'>
                                    <img loading="lazy" style={{ width: '25%' }} src="https://piloggroup.com/img/partners/GulHR_Logo.png" alt="" />
                                    <div>
                                        <h4>Gulf HR software</h4>
                                        <p>
                                            PiLog Saudi International has been a partner of Gulf Solutions since 2010 to specifically be a reseller of the Gulf HR software
                                        </p>

                                        <div className='line-div'></div>
                                        <Link to=''><h6>Learn More ⮕</h6></Link>
                                    </div>

                                </div>

                            </div>

                        </Col>

                    </Row>
                </Container>
            </section> */}

      <section className="my-3 py-3 ">
        <Container>
          <div>
            <h2 className="text-center" style={{ fontSize: "2.5rem" }}>
              Our Partners
            </h2>
            <p className="text-center" style={{ fontSize: "1.1rem" }}>
              Collaborating for excellence, amplifying impact, and driving
              success through trusted partnerships.
            </p>
          </div>
          <Row className="my-3">
            <Col lg={6} className="p-3">
              <div className="partner-cards">
                <img
                  loading="lazy"
                  style={{ width: "20%", textAlign: "center" }}
                  src="https://piloggroup.com/img/partners/SAP_Silver_Partner_Logo.png"
                  alt=""
                />
                <h5>SAP - Premium Partner</h5>
                <p style={{ fontSize: "15px", height: "100px", color: "#555" }}>
                  PiLog is Co-Innovated with SAP, implementing the SAP master
                  data governance application.
                </p>
                <button className="partner-btn">Learn More</button>
              </div>
            </Col>
            <Col lg={6} className="p-3">
              <div className="partner-cards">
                <img
                  loading="lazy"
                  style={{ width: "20%" }}
                  src="https://piloggroup.com/img/partners/ORACLE_Partnernetwork_%20Logo.png"
                  alt=""
                />
                <h5>Oracle - Partner Network</h5>
                <p style={{ fontSize: "15px", height: "100px", color: "#555" }}>
                  Oracle is one of the world leaders on integrated data
                  solutions for Customer Party data which is generally being
                  referred to as structured data.
                </p>
                <button className="partner-btn">Learn More</button>
              </div>
            </Col>
            <Col lg={6} className="p-3">
              <div className="partner-cards">
                <img
                  loading="lazy"
                  style={{ width: "22%" }}
                  src="https://piloggroup.com/img/partners/PARTNERS-LOGOS-03.png"
                  alt=""
                />
                <h5>Siveco Group- COSWIN</h5>
                <p style={{ fontSize: "15px", height: "100px", color: "#555" }}>
                  From 1993 PiLog has been a partner with Siveco Group, a major
                  international player and the European leader in the CMMS
                  market since 1986.
                </p>
                <button className="partner-btn">Learn More</button>
              </div>
            </Col>
            {/* <Col lg={6} className="p-3">
              <div className="partner-cards">
                <img
                  loading="lazy"
                  style={{ width: "20%" }}
                  src="https://piloggroup.com/img/partners/GulHR_Logo.png"
                  alt=""
                />
                <h5>Gulf HR software</h5>
                <p style={{ fontSize: "15px", height: "100px", color: "#555" }}>
                  PiLog Saudi International has been a partner of Gulf Solutions
                  since 2010 to specifically be a reseller of the Gulf HR
                  software.
                </p>
                <button className="partner-btn">Learn More</button>
              </div>
            </Col> */}
          </Row>
        </Container>
      </section>

      <section className="p-5" style={{ background: "#fff" }}>
        <Container>
          <div>
            <h2 className="text-center" style={{ fontSize: "2.5rem" }}>
              Partner Program
            </h2>
            <p className="text-center" style={{ fontSize: "1.1rem" }}>
              Let's collaborate, co-sell, and grow together with synergized
              partnerships
            </p>
          </div>
          <Row className="align-items-center mt-5">
            <Tabs
              defaultActiveKey="Sales Representative"
              id="fill-tab-example"
              className="mb-4 border"
              fill
            >
              <Tab eventKey="Sales Representative" title="Sales Representative">
                <div>
                  <Row>
                    <div className="partner-programs-heading">
                      <div className="empty-div"></div>
                      <h2 className="text-dark">Sales Representative</h2>
                    </div>
                    <Col lg={7}>
                      <div className="partner-programs-content">
                        <p className="d-flex text-align-left">
                          Sales Representative could be an Individual or a
                          Company/Organization. Anyone interested in the Sales
                          and Marketing of PiLog Products, Services, or
                          Solutions can benefit. As part of this program, the
                          Sales Representative will generate the leads to sell
                          our Solutions. He will be backed up by Pre-sales Team,
                          who will provide technical support and give demos to
                          New Customers when leads are generated.
                        </p>
                        <p>✦ Assessment & Approval</p>
                        <p>✦ Formal Agreement</p>
                        <p>✦ Basic Sales Kit</p>
                        <p>✦ CRM Access</p>
                        <p>✦ Sales Enablement</p>
                        <p>✦ Referral incentive</p>
                        <p>✦ Perks for Excellence</p>
                      </div>
                    </Col>
                    <Col lg={5}>
                      <img
                        loading="lazy"
                        className="w-100"
                        src={require("../../Components/Assets/AboutPiLog/Co-Sell Partner.png")}
                        alt="Spares Data Acquisition-1"
                        loading="lazy"
                      />
                    </Col>
                  </Row>
                </div>
              </Tab>
              <Tab eventKey="Co-Sell Partner" title="Co-Sell Partner">
                <div>
                  <Row>
                    <Col lg={7}>
                      <div className="partner-programs-heading">
                        <div className="empty-div"></div>
                        <h2 className="text-start">Co-Sell Partner</h2>
                      </div>
                      <div className="partner-programs-content">
                        <p className="d-flex text-align-left">
                          Co-sell partner can be either individual or companies
                          likely to extend their growth in PiLog. Become a
                          co-sell partner of PiLog with a qualified team
                          expertise in networking and market knowledge having
                          viability, values and ethics along with committed
                          tenure and non-competition.
                        </p>
                        <p>✦ Assessment & Approval</p>
                        <p>✦ Formal Agreement</p>
                        <p>✦ Advanced Sales Kit</p>
                        <p>✦ CRM Access</p>
                        <p>✦ Sales Enablement</p>
                        <p>✦ Accreditation</p>
                        <p>✦ Partner Advisor</p>
                        <p>✦ Revenue Share</p>
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <img
                          loading="lazy"
                          style={{ width: "100%" }}
                          src={require("../../Components/Assets/AboutPiLog/Co-Sell Partner.png")}
                          alt="Spares Data Acquisition-7"
                          loading="lazy"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
              <Tab eventKey="Co-Service Partner" title="Co-Service Partner">
                <div>
                  <Row>
                    <Col lg={7}>
                      <div className="partner-programs-heading">
                        <div className="empty-div"></div>
                        <h2 className="text-start">Co-Service Partner</h2>
                      </div>
                      <div className="partner-programs-content">
                        <p className="d-flex text-align-left">
                          In this partnership either the individual or companies
                          can sell our products and also support us in
                          implementation at the customer premises. Be a
                          co-service partner of PiLog with a qualified team
                          expertise in networking and market knowledge having
                          viability, values and ethics along with committed
                          tenure and non-competition.
                        </p>
                        <p>✦ Assessment & Approval</p>
                        <p>✦ Formal Agreement</p>
                        <p>✦ Advanced Sales Kit</p>
                        <p>✦ CRM Access</p>
                        <p>✦ Sales Enablement</p>
                        <p>✦ Accreditation</p>
                        <p>✦ Partner Advisor</p>
                        <p>✦ Revenue Share</p>
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <img
                          loading="lazy"
                          style={{ width: "100%" }}
                          src={require("../../Components/Assets/AboutPiLog/Co-Sell Partner.png")}
                          alt="Spares Data Acquisition-7"
                          loading="lazy"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
              <Tab
                eventKey="VAR or Strategic Partner"
                title="VAR or Strategic Partner"
              >
                <div>
                  <Row>
                    <Col lg={7}>
                      <div className="partner-programs-heading">
                        <div className="empty-div"></div>
                        <h2 className="text-start">VAR or Strategic Partner</h2>
                      </div>
                      <div className="partner-programs-content">
                        <p className="d-flex text-align-left">
                          VAR partner program is for companies who can sell our
                          products and also support us in implementation at the
                          customer premises. Be a VAR partner of PiLog with a
                          qualified team expertise in networking and market
                          knowledge having viability, values and ethics along
                          with committed numbers and non-competition.
                        </p>
                        <p>✦ Assessment & Approval</p>
                        <p>✦ Formal Agreement</p>
                        <p>✦ Basic Sales Kit</p>
                        <p>✦ CRM Access</p>
                        <p>✦ Sales Enablement Accreditation</p>
                        <p>✦ Partner Advisor</p>
                        <p>✦ Joint Marketing + GTM</p>
                        <p>✦ Scale-up Revenue Share</p>
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <img
                          loading="lazy"
                          style={{ width: "100%" }}
                          src={require("../../Components/Assets/AboutPiLog/Co-Sell Partner.png")}
                          alt="Spares Data Acquisition-7"
                          loading="lazy"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
            </Tabs>
          </Row>
        </Container>
      </section>

      <section className="leader-board">
        {/* Other Partner Program design is in text file */}
        <Container>
          <div>
            <h1 class="leader-category">EXECUTIVE TEAM</h1>
            <h2>Meet the executives leading PiLog Group</h2>
          </div>
          <Row>
            <Col lg={3}>
              <div class="leader-card">
                <div class="leader-card-image">
                  <img
                    loading="lazy"
                    className=""
                    src={require("../../Components/Assets/AboutPiLog/Dr.-Salomon-De-Jager_Pic.png")}
                    alt=""
                  />
                </div>
                <div class="leader-category"> Dr. Salomon De Jager </div>
                <div class="leader-heading"> Chairman, PiLog Group </div>

                <hr />
                <div className="leader-social-media">
                  <div className="d-flex justify-content-center align-items-center">
                    <Link to="">
                      <h6>Learn More ⮕</h6>
                    </Link>
                    <img
                      loading="lazy"
                      className=" bg-dark p-1 border rounded-2"
                      style={{ width: "10%", marginLeft: "4.5rem" }}
                      src={require("../../Components/Assets/Footer/SOCIAL-MEDIA_ICONS-06.png")}
                      alt=""
                    />
                    <img
                      loading="lazy"
                      className=" bg-dark p-1 border rounded-2"
                      style={{ width: "10%" }}
                      src={require("../../Components/Assets/Footer/SOCIAL-MEDIA_ICONS-09.png")}
                      alt=""
                    />
                  </div>
                  <div></div>
                </div>
              </div>
            </Col>
            <Col lg={3}>
              <div class="leader-card">
                <div class="leader-card-image">
                  <img
                    loading="lazy"
                    className=""
                    src={require("../../Components/Assets/AboutPiLog/Pieter-Strydom_Pic.png")}
                    alt=""
                  />
                </div>
                <div class="leader-category"> Pieter Strydom </div>
                <div class="leader-heading">
                  {" "}
                  Co - Chief Executive Officer,PiLog Group{" "}
                </div>

                <hr />
                <div className="leader-social-media">
                  <div className="d-flex justify-content-center align-items-center">
                    <Link to="">
                      <h6>Learn More ⮕</h6>
                    </Link>
                    <img
                      loading="lazy"
                      className=" bg-dark p-1 border rounded-2"
                      style={{ width: "10%", marginLeft: "4.5rem" }}
                      src={require("../../Components/Assets/Footer/SOCIAL-MEDIA_ICONS-06.png")}
                      alt=""
                    />
                    <img
                      loading="lazy"
                      className=" bg-dark p-1 border rounded-2"
                      style={{ width: "10%" }}
                      src={require("../../Components/Assets/Footer/SOCIAL-MEDIA_ICONS-09.png")}
                      alt=""
                    />
                  </div>
                  <div></div>
                </div>
              </div>
            </Col>
            <Col lg={3}>
              <div class="leader-card">
                <div class="leader-card-image">
                  <img
                    loading="lazy"
                    className=""
                    src={require("../../Components/Assets/AboutPiLog/Gideon-Huisamen_Pic.png")}
                    alt=""
                  />
                </div>
                <div class="leader-category">Gideon Huisamen </div>
                <div class="leader-heading">
                  {" "}
                  Co - Chief Executive Officer,PiLog Group
                </div>

                <hr />
                <div className="leader-social-media">
                  <div className="d-flex justify-content-center align-items-center">
                    <Link to="">
                      <h6>Learn More ⮕</h6>
                    </Link>
                    <img
                      loading="lazy"
                      className=" bg-dark p-1 border rounded-2"
                      style={{ width: "10%", marginLeft: "4.5rem" }}
                      src={require("../../Components/Assets/Footer/SOCIAL-MEDIA_ICONS-06.png")}
                      alt=""
                    />
                    <img
                      loading="lazy"
                      className=" bg-dark p-1 border rounded-2"
                      style={{ width: "10%" }}
                      src={require("../../Components/Assets/Footer/SOCIAL-MEDIA_ICONS-09.png")}
                      alt=""
                    />
                  </div>
                  <div></div>
                </div>
              </div>
            </Col>
            <Col lg={3}>
              <div class="leader-card">
                <div class="leader-card-image">
                  <img
                    loading="lazy"
                    className=""
                    src={require("../../Components/Assets/AboutPiLog/Dr.-Imad-Syed_Pic.png")}
                    alt=""
                  />
                </div>
                <div class="leader-category"> Dr. Imad Syed </div>
                <div class="leader-heading">Co - CEO and CIO, PiLog Group</div>

                <hr />
                <div className="leader-social-media">
                  <div className="d-flex justify-content-center align-items-center">
                    <Link to="">
                      <h6>Learn More ⮕</h6>
                    </Link>
                    <img
                      loading="lazy"
                      className=" bg-dark p-1 border rounded-2"
                      style={{ width: "10%", marginLeft: "4.5rem" }}
                      src={require("../../Components/Assets/Footer/SOCIAL-MEDIA_ICONS-06.png")}
                      alt=""
                    />
                    <img
                      loading="lazy"
                      className=" bg-dark p-1 border rounded-2"
                      style={{ width: "10%" }}
                      src={require("../../Components/Assets/Footer/SOCIAL-MEDIA_ICONS-09.png")}
                      alt=""
                    />
                  </div>
                  <div></div>
                </div>
              </div>
            </Col>
            <Col lg={3}>
              <div class="leader-card">
                <div class="leader-card-image">
                  <img
                    loading="lazy"
                    className=""
                    src={require("../../Components/Assets/AboutPiLog/Wynand-Nortje_Pic.png")}
                    alt=""
                  />
                </div>
                <div class="leader-category"> Wynand Nortje</div>
                <div class="leader-heading">
                  {" "}
                  Chief Technology Officer PiLog Group
                </div>

                <hr />
                <div className="leader-social-media">
                  <div className="d-flex justify-content-center align-items-center">
                    <Link to="">
                      <h6>Learn More ⮕</h6>
                    </Link>
                    <img
                      loading="lazy"
                      className=" bg-dark p-1 border rounded-2"
                      style={{ width: "10%", marginLeft: "4.5rem" }}
                      src={require("../../Components/Assets/Footer/SOCIAL-MEDIA_ICONS-06.png")}
                      alt=""
                    />
                    <img
                      loading="lazy"
                      className=" bg-dark p-1 border rounded-2"
                      style={{ width: "10%" }}
                      src={require("../../Components/Assets/Footer/SOCIAL-MEDIA_ICONS-09.png")}
                      alt=""
                    />
                  </div>
                  <div></div>
                </div>
              </div>
            </Col>
            <Col lg={3}>
              <div class="leader-card">
                <div class="leader-card-image">
                  <img
                    loading="lazy"
                    className=""
                    src={require("../../Components/Assets/AboutPiLog/Tracy-Bennett_Pic.png")}
                    alt=""
                  />
                </div>
                <div class="leader-category"> Tracy Bennett </div>
                <div class="leader-heading">
                  {" "}
                  Chief Technology Officer PiLog Group{" "}
                </div>

                <hr />
                <div className="leader-social-media">
                  <div className="d-flex justify-content-center align-items-center">
                    <Link to="">
                      <h6>Learn More ⮕</h6>
                    </Link>
                    <img
                      loading="lazy"
                      className=" bg-dark p-1 border rounded-2"
                      style={{ width: "10%", marginLeft: "4.5rem" }}
                      src={require("../../Components/Assets/Footer/SOCIAL-MEDIA_ICONS-06.png")}
                      alt=""
                    />
                    <img
                      loading="lazy"
                      className=" bg-dark p-1 border rounded-2"
                      style={{ width: "10%" }}
                      src={require("../../Components/Assets/Footer/SOCIAL-MEDIA_ICONS-09.png")}
                      alt=""
                    />
                  </div>
                  <div></div>
                </div>
              </div>
            </Col>
            <Col lg={3}>
              <div class="leader-card">
                <div class="leader-card-image">
                  <img
                    loading="lazy"
                    className=""
                    src={require("../../Components/Assets/AboutPiLog/Reinhard-Schiel_Pic.png")}
                    alt=""
                  />
                </div>
                <div class="leader-category">Reinhard Schiel</div>
                <div class="leader-heading">
                  {" "}
                  Chief Operating Officer Europe and CIS - Zone 2
                </div>

                <hr />
                <div className="leader-social-media">
                  <div className="d-flex justify-content-center align-items-center">
                    <Link to="">
                      <h6>Learn More ⮕</h6>
                    </Link>
                    <img
                      loading="lazy"
                      className=" bg-dark p-1 border rounded-2"
                      style={{ width: "10%", marginLeft: "4.5rem" }}
                      src={require("../../Components/Assets/Footer/SOCIAL-MEDIA_ICONS-06.png")}
                      alt=""
                    />
                    <img
                      loading="lazy"
                      className=" bg-dark p-1 border rounded-2"
                      style={{ width: "10%" }}
                      src={require("../../Components/Assets/Footer/SOCIAL-MEDIA_ICONS-09.png")}
                      alt=""
                    />
                  </div>
                  <div></div>
                </div>
              </div>
            </Col>
            <Col lg={3}>
              <div class="leader-card">
                <div class="leader-card-image">
                  <img
                    loading="lazy"
                    className=""
                    src={require("../../Components/Assets/AboutPiLog/Dr.-Salomon-De-Jager_Pic.png")}
                    alt=""
                  />
                </div>
                <div class="leader-category">Johan Zwarts </div>
                <div class="leader-heading">
                  {" "}
                  Chief Operating Officer Africa - Zone2{" "}
                </div>

                <hr />
                <div className="leader-social-media">
                  <div className="d-flex justify-content-center align-items-center">
                    <Link to="">
                      <h6>Learn More ⮕</h6>
                    </Link>
                    <img
                      loading="lazy"
                      className=" bg-dark p-1 border rounded-2"
                      style={{ width: "10%", marginLeft: "4.5rem" }}
                      src={require("../../Components/Assets/Footer/SOCIAL-MEDIA_ICONS-06.png")}
                      alt=""
                    />
                    <img
                      loading="lazy"
                      className=" bg-dark p-1 border rounded-2"
                      style={{ width: "10%" }}
                      src={require("../../Components/Assets/Footer/SOCIAL-MEDIA_ICONS-09.png")}
                      alt=""
                    />
                  </div>
                  <div></div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="py-5">
        <Container>
          <div>
            <h2 className="text-center" style={{ fontSize: "2.5rem" }}>
              Zonal Offices
            </h2>
          </div>
          <Row>
            <Col lg={12}>
              <img
                loading="lazy"
                className="w-100"
                src={require("../../Components/Assets/AboutPiLog/MAP-03.png")}
                alt=""
              />
            </Col>
            <Col lg={3}>
              <div className="zonal-office">
                <LocationOnIcon className="z-icon" />
                <h4> South Africa</h4>
                <p style={{ fontSize: "14px" }}>
                  Building 5, Centurion Gate, Akkerboom St, Zwartkop, Centurion,
                  South Africa - 0157
                </p>
                <p>Email : info@piloggroup.com</p>
                <p>
                  <b>Tel :</b> +27 12 663 2210
                </p>
              </div>
            </Col>
            <Col lg={3}>
              <div className="zonal-office">
                <LocationOnIcon className="z-icon" />
                <h4> Americas</h4>
                <p style={{ fontSize: "14px" }}>
                  401 College St Suite 208 Montgomery, TX 77356
                </p>
                <p>Email : info@piloggroup.com</p>
                <p>
                  <b>Tel :</b> +1 888 291 7242
                </p>
              </div>
            </Col>
            <Col lg={3}>
              <div className="zonal-office">
                <LocationOnIcon className="z-icon" />
                <h4> Europe and CIS</h4>
                <p style={{ fontSize: "14px" }}>
                  6B Mykilsko Slobidska St, Kyiv Ukraine. 02002
                </p>
                <p>Email : info@piloggroup.com</p>
                <p>Tel : +380 96 309 5232</p>
              </div>
            </Col>
            <Col lg={3}>
              <div className="zonal-office">
                <LocationOnIcon className="z-icon" />
                <h4> India</h4>
                <p style={{ fontSize: "14px" }}>
                  3rd Floor, Unit-1, MJR Magnifique, Raidurg Navakhalsa,
                  Hyderabad, Telangana - 500 008
                </p>
                <p>Email : info@piloggroup.com</p>
                <p>Tel : +91 630 176 0928</p>
              </div>
            </Col>
            <Col lg={3}>
              <div className="zonal-office">
                <LocationOnIcon className="z-icon" />
                <h4> Middle East North</h4>
                <p style={{ fontSize: "14px" }}>
                  Office 2-2, Palm Commercial Centre, Fanateer, Jubail, Kingdom
                  of Saudi Arabia
                </p>
                <p>Email : info@piloggroup.com</p>
                <p> Tel : +966 13 347 2595</p>
              </div>
            </Col>
            {/* <Col lg={3}>
              <div className="zonal-office">
                <LocationOnIcon className="z-icon" />
                <h4> Middle East North</h4>
                <p style={{ fontSize: "14px" }}>
                  Building no:67, Street No:250, Zone No:45, Regus D Ring Road,
                  Doha,Qatar
                </p>
                <p>Email : qatar@piloggroup.com</p>
                <p>Phone : +97444231100</p>
              </div>
            </Col> */}
            <Col lg={3}>
              <div className="zonal-office">
                <LocationOnIcon className="z-icon" />
                <h4> Middle East South</h4>
                <p style={{ fontSize: "14px" }}>
                  4th Floor, office No.406, Abudhabi Plaza Tower, Fatima Bint.
                  Mubarak Street,Abu Dhabi
                </p>
                <p>Email : info@piloggroup.com</p>
                <p> Tel : +971 2 555 6722</p>
              </div>
            </Col>
            <Col lg={3}>
              <div className="zonal-office">
                <LocationOnIcon className="z-icon" />
                <h4> Belgium</h4>
                <p style={{ fontSize: "14px" }}>
                  Building 5, Centurion Gate, Akkerboom St, Zwartkop, Centurion,
                  South Africa - 0157
                </p>
                <p>Email : info@piloggroup.com</p>
                <p>Phone : +27 12 663 2210</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};
