import styles from "./styles.module.css";
import {
  Box,
  Flex,
  Grid,
  Text,
  List,
  Container,
  Button,
} from "@mantine/core";
import { Carousel } from "@mantine/carousel";

const EnduringAssetLifecycleManagement = () => {
  return (
    <>
      <Box component="section">
        <Box component="section" className={styles.backgroundImageEndure}>
          <Box p={"10rem 0"}>
            <Container>
              <h1 className="text-center">Enduring Asset Lifecycle Management</h1>
              <Text component="p" className="text-center fs-5">
                With most of the organizations looking at IT componentset and
                Platform, Data Consolidation and Analytics - Robust Digital
                Roadmap
              </Text>
            </Container>
          </Box>
        </Box>
        <Box component="section">
          <Box p={"5rem 0"}>
            <Container>
              <Grid>
                <Grid.Col span={{ bcomponente: 12, md: 6, lg: 6 }}>
                  <img
                    width={"100%"}
                    src={require("../../Components/Assets/enduranceAssets/Enduring-Asset-Lifecycle-Management_Img.png")}
                    alt=""
                  />
                </Grid.Col>
                <Grid.Col span={{ bcomponente: 12, md: 6, lg: 6 }}>
                  <Text>
                    Asset utilization, performance, productivity drives
                    organization efficiencies. Organizations have ZERO Appetite
                    for asset replacement or asset tracking software and more
                    automation. It s critical to manage and monitor old
                    warhorses to perform and excel.
                  </Text>
                  <h4 style={{ margin: "2rem 0" }}>
                    “ Prominent leaders always self reflect the challenges… WHY
                    and WHAT!!! ”
                  </h4>
                  <List>
                    <List.Item>Asset performance visibility</List.Item>
                    <List.Item>Confidence on maintenance operations</List.Item>
                    <List.Item>
                      Achieve industry benchmarks KPIs and KRAs
                    </List.Item>
                    <List.Item>Lowering Total Cost of Ownership</List.Item>
                    <List.Item>
                      Create involving model design, capture information and Tag
                      it as appropriate
                    </List.Item>
                    <List.Item>Manage or Organize data elements</List.Item>
                    <List.Item>
                      Distribute to applications and processes
                    </List.Item>
                    <List.Item>
                      Retrieve structured content and effectively reuse
                    </List.Item>
                    <List.Item>
                      Archive all information in organized repositories
                    </List.Item>
                  </List>
                </Grid.Col>
              </Grid>
            </Container>
          </Box>
        </Box>
        <Box component="section"  style={{backgroundColor:"#AEE1FF"}}>
          <Box m={"4rem 0"} p={"5rem 0"}>
            <Container>
              <Grid>
                <Grid.Col span={{ bcomponente: 12, md: 6, lg: 6 }}>
                  <Text mb={"2rem"}>
                    With COVID-19, business is gearing on innovative ways of
                    executing operations, maintenance, warehousing,
                    transportation, and supply chain components complying with
                    norms, guidelines dynamically set by regulatory authorities
                    from time to time. New trends will force more automation, ML
                    and AI based O and M, etc. And to make the right investment,
                    it s very imperative to have correct and up to date
                    information about Asset Master, Meta Data, Predictive
                    Analytics
                  </Text>
                  <List>
                    <List.Item>
                      AS–IS Maintenance Maturity Model Assessment
                    </List.Item>
                    <List.Item>Asset Criticality Assessment</List.Item>
                    <List.Item>
                      EAM Data Objects Assessment and Management
                    </List.Item>
                    <List.Item>SOPs and Strategy – Asset Management</List.Item>
                    <List.Item>Optimization of Maintenance Planning</List.Item>
                    <List.Item>
                      Allied Process Optimization: Sourcing and Procurement,
                      Inventory, Partner Collaboration and Production
                    </List.Item>
                    <List.Item>
                      Continual Monitoring, Reporting, and Control
                    </List.Item>
                  </List>
                </Grid.Col>
                <Grid.Col span={{ bcomponente: 12, md: 6, lg: 6 }}>
                  <img
                    src={require("../../Components/Assets/enduranceAssets/Enduring-Asset-Lifecycle-Management_Img2.png")}
                    alt=""
                    width={"100%"}
                  />
                </Grid.Col>
              </Grid>
            </Container>
          </Box>
        </Box>
        <Box component="section" >
          <Box m={"4rem 0"} p={"4rem 0"}>
            <Container>
              <Grid mb={"4rem"}>
                <Grid.Col span={{ bcomponente: 12, md: 6, lg: 6 }}>
                  <Carousel withIndicators dragFree slideGap="md" align="start">
                    <Carousel.Slide>
                      <img
                        width={"100%"}
                        src={require("../../Components/Assets/enduranceAssets/ALM_Images-04.png")}
                        alt=""
                      />
                    </Carousel.Slide>
                    <Carousel.Slide>
                      <img
                        width={"100%"}
                        src={require("../../Components/Assets/enduranceAssets/ALM_Images-05.png")}
                        alt=""
                      />
                    </Carousel.Slide>
                  </Carousel>
                </Grid.Col>
                <Grid.Col span={{ bcomponente: 12, md: 6, lg: 6 }}>
                  <Box>
                    <Box>
                      <List>
                        <List.Item>
                          MMM = f (cost of maintenance, MRO inventory, asset
                          availability and performance, Skill availability,
                          Governance, and Compliance…)
                        </List.Item>
                        <List.Item>
                          PiLog Maintenance Strategy Framework (MSF) to enable
                          Equipment Criticality, Failure Data and Risk
                          Assessment, Maintenance Strategy, etc. to enable
                          roadmap for Reliability Centered Maintenance
                        </List.Item>
                        <List.Item>
                          Formulation and Refinement of Maintenance Strategy,
                          Plans, Frequency
                        </List.Item>
                      </List>
                    </Box>
                  </Box>
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <Box>
                    <Box>
                      <List>
                        <List.Item>
                          Formulation and Refinement of Maintenance Tasks
                          Planned, Predictive Maintenance, Condition Based
                          Monitoring
                        </List.Item>
                        <List.Item>
                          Continual revival and review of Maintenance Strategies
                          with predictive analysis on maintenance history,
                          design, and supply chain collaboration
                        </List.Item>
                        <List.Item>
                          Reliability Centered Maintenance (RCM), driving asset
                          performance
                        </List.Item>
                        <List.Item>
                          Continual Monitoring, Reporting, and Control
                        </List.Item>
                      </List>
                    </Box>
                  </Box>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <Carousel withIndicators dragFree slideGap="md" align="start">
                    <Carousel.Slide>
                      <img
                        width={"100%"}
                        src={require("../../Components/Assets/enduranceAssets/ALM_Images-04.png")}
                        alt=""
                      />
                    </Carousel.Slide>
                    <Carousel.Slide>
                      <img
                        width={"100%"}
                        src={require("../../Components/Assets/enduranceAssets/ALM_Images-06.png")}
                        alt=""
                      />
                    </Carousel.Slide>
                  </Carousel>
                </Grid.Col>
              </Grid>
            </Container>
          </Box>
        </Box>
        <Box component="section"  style={{backgroundColor:"#AEE1FF"}}>
          <Box m={"4rem 0"} p={"5rem 0"}>
            <Container>
              <Grid>
                <Grid.Col span={{ bcomponente: 12, md: 6, lg: 6 }}>
                  <img
                    src={require("../../Components/Assets/enduranceAssets/Enduring-Asset-Lifecycle-Management_Img3.png")}
                    alt=""
                    width={"100%"}
                  />
                </Grid.Col>
                <Grid.Col span={{ bcomponente: 12, md: 6, lg: 6 }}>
                  <Flex direction={"column"} gap="20px">
                    <Box>
                      <h3>1.Asset Master and Meta Data Models</h3>
                      <List>
                        <List.Item>
                          Equipment tag extraction and create
                        </List.Item>
                        <List.Item>
                          Document Linked, indexed and enriched documentation
                          improving operational visibility and control
                        </List.Item>
                        <List.Item>
                          Field data verification and plant walk down aiding the
                          enrichment of critical master data
                        </List.Item>
                        <List.Item>
                          SPIR’s, an operational lifeline for equipment health
                          management
                        </List.Item>
                        <List.Item>
                          Functional location hierarchy build based on
                          complexity and criticality
                        </List.Item>
                        <List.Item>Measuring Points and Counters</List.Item>
                        <List.Item>
                          The Scientific approach for maintenance task execution
                          with Task List
                        </List.Item>
                      </List>
                    </Box>
                    <Box>
                      <h3>
                        2. Shared services Master Data Knowledge Process
                        Outsourcing
                      </h3>
                    </Box>
                    <Box>
                      <h3>3.Continual Monitoring, Reporting, and Control</h3>
                    </Box>
                  </Flex>
                </Grid.Col>
              </Grid>
            </Container>
          </Box>
        </Box>
        <Box component="section">
          <Box m={"4rem 0"}  p={"4rem 0"}>
            <Container>
              <Grid align="center">
                <Grid.Col span={{ bcomponente: 12, md: 6, lg: 6 }}>
                  <Box>
                    <Text>
                      As most of the businesses are controlled by Master and
                      Meta Management and thought leaders effective decision
                      with Augmented Predictive Analytics, PiLog Systematic and
                      Innovative Approach help organizations with Kaizen to
                      Transformation !!!
                    </Text>
                  </Box>
                </Grid.Col>
                <Grid.Col span={{ bcomponente: 12, md: 6, lg: 6 }}>
                  <img
                    src={require("../../Components/Assets/enduranceAssets/ALM_Images-08.png")}
                    alt=""
                  />
                </Grid.Col>
              </Grid>
            </Container>
          </Box>
        </Box>
        <Box component="section" p={"5rem 0"} style={{backgroundColor:"#AEE1FF"}} >
          <Box style={{textAlign:"center"}}>
            <Container>
              <h1 className={styles['endurance-header-bottom']}>
                Client Value Management and Success Stories
              </h1>
              <Text>
                A Petrochemical Major in KSA has invited PiLog and multiple
                other consulting leaders to identify the challenges and define
                problem statements in their Asset Life Cycle Management to
                optimize their asset performance and endurance in long run
              </Text>
              <Button mt="lg">Download Success Story</Button>
            </Container>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default EnduringAssetLifecycleManagement;
