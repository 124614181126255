import { useState } from 'react';
import './Subscribe.css'
import { Button, Col, Container, Row } from 'react-bootstrap'
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Box
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { BookADemo } from '../ExtraComponents/BookADemo';
import { Link } from 'react-router-dom';
import TableAcordian from './TableAcordian';

export const Subscribe = () => {

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    // const [expanded, setExpanded] = useState(false);

    // const handleChange = () => {
    //     setExpanded(!expanded);
    // };

    return (
        <div>

            <section className='subscription-part1'>
                <Container>
                    <Row>
                        <div className='subscription-heading'>
                            <h1>Lean Data Governance</h1>
                            <p>Read, What the users are saying</p>
                            <div className='subscription-head-images'>
                                <img  style={{width:'125px'}} src={require("../Assets/Subscription/GPI-CC-2024-color.png")} alt="gggg" loading='lazy'/>
                                <img src={require("../Assets/Subscription/customer-first-gartner-blue.png")} alt="gggg" loading='lazy'/>
                                <img src={require("../Assets/Subscription/software_review.png")} alt="gggg" loading='lazy'/>
                            </div>
                        </div>

                    </Row>
                </Container>
            </section>
            <section className='subscription-part2'>
                <Container>
                    <Row className='d-flex justify-content-center align-items-center'>
                        <Col lg={4} md={10} xs={12} sm={12}>
                            <div className="subscription-card">
                                <div className="subscription-card-title">
                                    <h5>Basic</h5>
                                    <p>Only ONE Domain</p>
                                </div>
                                <div className='package-users'>
                                    <h6>5 Users Packages</h6>
                                    <h6>Ideal for Small Businesses</h6>
                                </div>
                                <div className='package-users'>
                                    <h6>[Annual Subscription Auto Renews]</h6>
                                </div>
                                <div className='buy-now-btn'>
                                    <Button className='buy-now-btn-design'><i class="fa fa-shopping-cart" aria-hidden="true"></i>Buy Now</Button>
                                </div>
                                <div>
                                    <div className='subscription-card-points'>
                                        <i class="fa fa-check" aria-hidden="true"></i><p>ISO Compliant Governance Processes</p>

                                    </div>
                                    <div className='subscription-card-points'>
                                        <i class="fa fa-check" aria-hidden="true"></i><p>Industry-proven Best Practices</p>
                                    </div>
                                    <div className='subscription-card-points'>
                                        <i class="fa fa-check" aria-hidden="true"></i><p>Configure to use in <i class="fa fa-angle-left" aria-hidden="true"></i> 60 mins.</p>
                                    </div>
                                    <div className='subscription-card-points'>
                                        <i class="fa fa-check" aria-hidden="true"></i><p>Gartner Recognized MDM Solution</p>
                                    </div>
                                    <div className='subscription-card-points'>
                                        <i class="fa fa-check" aria-hidden="true"></i><p>Export Your Data in</p>
                                    </div>
                                    <div className='subscription-card-points'>
                                        <i class="fa fa-check" aria-hidden="true"></i><p>Robotic Process Automation & Data Quality Assesment [ AI ]</p>
                                    </div>
                                </div>
                                <div className='mx-1 my-3'>
                                    <Row className='d-flex justify-content-center px-3 mt-3'>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/ProductMaster.png")} alt="gggg" loading='lazy'/>
                                                <p>Product</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/_ServiceMaster_.png")} alt="gggg" loading='lazy'/>
                                                <p>Service</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/_PartnerMaster_.png")} alt="gggg" loading='lazy'/>
                                                <p>Partner</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/_Vendor_.png")} alt="gggg" loading='lazy'/>
                                                <p>Vendor</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/_Customer_.png")} alt="gggg" loading='lazy'/>
                                                <p>Customer</p>
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row className='px-3 mt-2'>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/_AssetMaste_.png")} alt="gggg" loading='lazy'/>
                                                <p>Asset</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/_iSPIR_.png")} alt="gggg" loading='lazy'/>
                                                <p>iSPIR</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/_Employee_.png")} alt="gggg" loading='lazy'/>
                                                <p>Employee</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/_Fixed_.png")} alt="gggg" loading='lazy'/>
                                                <p>iFAR</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/_PMMaintanance_.png")} alt="gggg" loading='lazy'/>
                                                <p>Maint-Task</p>
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row className='px-3 mt-2'>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/_eBom_.png")} alt="gggg" loading='lazy'/>
                                                <p>eBOM</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/_fBom_.png")} alt="gggg" loading='lazy'/>
                                                <p>fBOM</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/_mBom_.png")} alt="gggg" loading='lazy'/>
                                                <p>mBOM</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/_PMMeasuring_.png")} alt="gggg" loading='lazy'/>
                                                <p>Measure Point</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/_PM Work_.png")} alt="gggg" loading='lazy'/>
                                                <p>Work-Center</p>
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row className='px-3 mt-2'>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/mss.png")} alt="gggg" loading='lazy'/>
                                                <p>MSS</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/_Leave_.png")} alt="gggg" loading='lazy'/>
                                                <p>Leave</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/_PMTask_.png")} alt="gggg" loading='lazy'/>
                                                <p>Task List</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/_Incident_.png")} alt="gggg" loading='lazy'/>
                                                <p>Incident</p>
                                            </div>
                                        </Col>


                                    </Row>
                                </div>
                            </div>
                            <div className='m-3 d-flex justify-content-center align-items-center'>
                                <Link to="https://calendly.com/demos-piloggroup">
                                    <BookADemo name="Schedule a Demo" />
                                </Link>
                            </div>
                        </Col>
                        <Col lg={4} md={10} xs={12} sm={12}>
                            <div className="subscription-card">
                                <div className="subscription-card-title">
                                    <h5>Professional</h5>
                                    <p>Upto THREE Domains</p>
                                </div>
                                <div className='package-users'>
                                    <h6>20 Users Packages</h6>
                                    <h6>Ideal for Small Businesses</h6>
                                </div>
                                <div className='package-users'>
                                    <h6>[Annual Subscription Auto Renews]</h6>
                                </div>
                                <div className='buy-now-btn'>
                                    <Button className='buy-now-btn-design'><i class="fa fa-shopping-cart" aria-hidden="true"></i>Buy Now</Button>
                                </div>
                                <div>
                                    <div className='subscription-card-points'>
                                        <i class="fa fa-check" aria-hidden="true"></i><p>ISO Compliant Governance Processes</p>

                                    </div>
                                    <div className='subscription-card-points'>
                                        <i class="fa fa-check" aria-hidden="true"></i><p>Industry-proven Best Practices</p>
                                    </div>
                                    <div className='subscription-card-points'>
                                        <i class="fa fa-check" aria-hidden="true"></i><p>Configure to use in <i class="fa fa-angle-left" aria-hidden="true"></i> 60 mins.</p>
                                    </div>
                                    <div className='subscription-card-points'>
                                        <i class="fa fa-check" aria-hidden="true"></i><p>Gartner Recognized MDM Solution</p>
                                    </div>
                                    <div className='subscription-card-points'>
                                        <i class="fa fa-check" aria-hidden="true"></i><p>Export Your Data in</p>
                                    </div>
                                    <div className='subscription-card-points'>
                                        <i class="fa fa-check" aria-hidden="true"></i><p>Robotic Process Automation & Data Quality Assesment [ AI ]</p>
                                    </div>
                                </div>
                                <div className='mx-1 my-3'>
                                    <Row className='d-flex justify-content-center px-3 mt-3'>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/ProductMaster.png")} alt="gggg" loading='lazy'/>
                                                <p>Product</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/_ServiceMaster_.png")} alt="gggg" loading='lazy'/>
                                                <p>Service</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/_PartnerMaster_.png")} alt="gggg" loading='lazy'/>
                                                <p>Partner</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/_Vendor_.png")} alt="gggg" loading='lazy'/>
                                                <p>Vendor</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/_Customer_.png")} alt="gggg" loading='lazy'/>
                                                <p>Customer</p>
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row className='px-3 mt-2'>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/_AssetMaste_.png")} alt="gggg" loading='lazy'/>
                                                <p>Asset</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/_iSPIR_.png")} alt="gggg" loading='lazy'/>
                                                <p>iSPIR</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/_Employee_.png")} alt="gggg" loading='lazy'/>
                                                <p>Employee</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/_Fixed_.png")} alt="gggg" loading='lazy'/>
                                                <p>iFAR</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/_PMMaintanance_.png")} alt="gggg" loading='lazy'/>
                                                <p>Maint-Task</p>
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row className='px-3 mt-2'>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/_eBom_.png")} alt="gggg" loading='lazy'/>
                                                <p>eBOM</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/_fBom_.png")} alt="gggg" loading='lazy'/>
                                                <p>fBOM</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/_mBom_.png")} alt="gggg" loading='lazy'/>
                                                <p>mBOM</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/_PMMeasuring_.png")} alt="gggg" loading='lazy'/>
                                                <p>Measure Point</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/_PM Work_.png")} alt="gggg" loading='lazy'/>
                                                <p>Work-Center</p>
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row className='px-3 mt-2'>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/mss.png")} alt="gggg" loading='lazy'/>
                                                <p>MSS</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/_Leave_.png")} alt="gggg" loading='lazy'/>
                                                <p>Leave</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/_PMTask_.png")} alt="gggg" loading='lazy'/>
                                                <p>Task List</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='service-images'>
                                                <img src={require("../Assets/Subscription/SaaS/_Incident_.png")} alt="gggg" loading='lazy'/>
                                                <p>Incident</p>
                                            </div>
                                        </Col>


                                    </Row>
                                </div>
                            </div>
                            <div className='m-3 d-flex justify-content-center align-items-center'>
                                <Link to="https://calendly.com/demos-piloggroup">
                                    <BookADemo name="Schedule a Demo" />
                                </Link>
                            </div>
                        </Col>
                        <Col lg={4} md={10} xs={12} sm={12}>
                            <div className="subscription-card">
                                <div className="subscription-card-title">
                                    <h5>Enterprise</h5>
                                    <p>ANY Available Domains</p>
                                </div>
                                <div className='package-users'>
                                    <h6>100 Users Packages</h6>
                                    <h6>Ideal for Small Businesses</h6>
                                </div>
                                <div className='package-users'>
                                    <h6>[Annual Subscription Auto Renews]</h6>
                                </div>
                                <div className='buy-now-btn'>
                                    <Button className='buy-now-btn-design'><i class="fa fa-shopping-cart" aria-hidden="true"></i>Buy Now</Button>
                                </div>
                                <div>
                                    <div className='subscription-card-points'>
                                        <i class="fa fa-check" aria-hidden="true"></i><p>ISO Compliant Governance Processes</p>

                                    </div>
                                    <div className='subscription-card-points'>
                                        <i class="fa fa-check" aria-hidden="true"></i><p>Industry-proven Best Practices</p>
                                    </div>

                                    <div className='subscription-card-points'>
                                        <i class="fa fa-check" aria-hidden="true"></i><p>Gartner Recognized MDM Solution</p>
                                    </div>
                                    <div className='subscription-card-points'>
                                        <i class="fa fa-check" aria-hidden="true"></i><p>Export Your Data in</p>
                                    </div>
                                    <div className='subscription-card-points'>
                                        <i class="fa fa-check" aria-hidden="true"></i><p>Robotic Process Automation & Data Quality Assesment [ AI ]</p>
                                    </div>
                                    <div className='subscription-card-points p-2'>

                                    </div>
                                    <div className='mx-1 my-4'>
                                        <Row className='d-flex justify-content-center px-3 mt-3'>
                                            <Col>
                                                <div className='service-images'>
                                                    <img src={require("../Assets/Subscription/SaaS/ProductMaster.png")} alt="gggg" loading='lazy'/>
                                                    <p>Product</p>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className='service-images'>
                                                    <img src={require("../Assets/Subscription/SaaS/_ServiceMaster_.png")} alt="gggg" loading='lazy'/>
                                                    <p>Service</p>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className='service-images'>
                                                    <img src={require("../Assets/Subscription/SaaS/_PartnerMaster_.png")} alt="gggg" loading='lazy'/>
                                                    <p>Partner</p>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className='service-images'>
                                                    <img src={require("../Assets/Subscription/SaaS/_Vendor_.png")} alt="gggg" loading='lazy'/>
                                                    <p>Vendor</p>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className='service-images'>
                                                    <img src={require("../Assets/Subscription/SaaS/_Customer_.png")} alt="gggg" loading='lazy'/>
                                                    <p>Customer</p>
                                                </div>
                                            </Col>

                                        </Row>
                                        <Row className='px-3 mt-2'>
                                            <Col>
                                                <div className='service-images'>
                                                    <img src={require("../Assets/Subscription/SaaS/_AssetMaste_.png")} alt="gggg" loading='lazy'/>
                                                    <p>Asset</p>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className='service-images'>
                                                    <img src={require("../Assets/Subscription/SaaS/_iSPIR_.png")} alt="gggg" loading='lazy'/>
                                                    <p>iSPIR</p>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className='service-images'>
                                                    <img src={require("../Assets/Subscription/SaaS/_Employee_.png")} alt="gggg" loading='lazy'/>
                                                    <p>Employee</p>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className='service-images'>
                                                    <img src={require("../Assets/Subscription/SaaS/_Fixed_.png")} alt="gggg" loading='lazy'/>
                                                    <p>iFAR</p>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className='service-images'>
                                                    <img src={require("../Assets/Subscription/SaaS/_PMMaintanance_.png")} alt="gggg" loading='lazy'/>
                                                    <p>Maint-Task</p>
                                                </div>
                                            </Col>

                                        </Row>
                                        <Row className='px-3 mt-2'>
                                            <Col>
                                                <div className='service-images'>
                                                    <img src={require("../Assets/Subscription/SaaS/_eBom_.png")} alt="gggg" loading='lazy'/>
                                                    <p>eBOM</p>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className='service-images'>
                                                    <img src={require("../Assets/Subscription/SaaS/_fBom_.png")} alt="gggg" loading='lazy'/>
                                                    <p>fBOM</p>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className='service-images'>
                                                    <img src={require("../Assets/Subscription/SaaS/_mBom_.png")} alt="gggg" loading='lazy'/>
                                                    <p>mBOM</p>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className='service-images'>
                                                    <img src={require("../Assets/Subscription/SaaS/_PMMeasuring_.png")} alt="gggg" loading='lazy'/>
                                                    <p>Measure Point</p>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className='service-images'>
                                                    <img src={require("../Assets/Subscription/SaaS/_PM Work_.png")} alt="gggg" loading='lazy'/>
                                                    <p>Work-Center</p>
                                                </div>
                                            </Col>

                                        </Row>
                                        <Row className='px-3 mt-2'>
                                            <Col>
                                                <div className='service-images'>
                                                    <img src={require("../Assets/Subscription/SaaS/mss.png")} alt="gggg" loading='lazy'/>
                                                    <p>MSS</p>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className='service-images'>
                                                    <img src={require("../Assets/Subscription/SaaS/_Leave_.png")} alt="gggg" loading='lazy'/>
                                                    <p>Leave</p>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className='service-images'>
                                                    <img src={require("../Assets/Subscription/SaaS/_PMTask_.png")} alt="gggg" loading='lazy'/>
                                                    <p>Task List</p>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className='service-images'>
                                                    <img src={require("../Assets/Subscription/SaaS/_Incident_.png")} alt="gggg" loading='lazy'/>
                                                    <p>Incident</p>
                                                </div>
                                            </Col>


                                        </Row>
                                    </div>
                                </div>
                            </div>
                            <div className='m-3 d-flex justify-content-center align-items-center'>
                                <Link to="https://calendly.com/demos-piloggroup">
                                    <BookADemo name="Schedule a Demo" />
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </section>

<section>
    <Container className='py-5'>
        {/* <SubscriptionTable/> */}
        <TableAcordian/>
    </Container>
</section>

            <section className='subscription-faq'>
                <Container>
                    <div className='faq-title'>
                        <h3>Frequently Asked Questions ?</h3>
                    </div>
                    <Row className='mt-4'>
                        <Col lg={7} className='mt-4'>
                           

                            <div className='faq-accordian'>
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <AccordionSummary

                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Box display="flex" alignItems="flex-start">
                                            {expanded === 'panel1' ? <RemoveCircleIcon /> : <AddCircleIcon />}
                                            <Typography component="div" sx={{ ml: 2 }} className='text-start'>
                                                <b>What is Lean Governance Data ?</b>
                                            </Typography>
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography className='text-start d-flex justify-content-center align-items-center' >
                                            <p>
                                                A simplified version of Master Data Management(MDM) with enriched User Experience, hosted on Cloud platform with high availability.
                                            </p>
                                            <div>
                                                <Link to="https://www.youtube.com/watch?v=zh3iXnS3FBI" target='blank'>
                                                    <img style={{ width: '80px' }} src={require("../Assets/Subscription/youtube-icon-png.webp")} alt="" loading='lazy' />
                                                </Link>
                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                    <AccordionSummary

                                        aria-controls="panel2bh-content"
                                        id="panel2bh-header"
                                    >
                                        <Box display="flex" alignItems="flex-start">
                                            {expanded === 'panel2' ? <RemoveCircleIcon /> : <AddCircleIcon />}
                                            <Typography component="div" sx={{ ml: 2 }} className='text-start'>
                                                <b> On-Cloud vs On-Premise What s the right plan?</b>
                                            </Typography>
                                        </Box>


                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography className='text-start d-flex justify-content-center align-items-center'>
                                            <p>
                                                'Choose the Best Plan' is a way to help/guide what's best for the organization, navigate through the provided guide to seek assistance on selecting the right version/plan.

                                            </p>
                                            <div>
                                                <Link to="https://www.youtube.com/watch?v=_MJtKSAja84" target='blank'>
                                                    <img style={{ width: '80px' }} src={require("../Assets/Subscription/youtube-icon-png.webp")} alt="" loading='lazy'/>
                                                </Link>
                                            </div>

                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                    <AccordionSummary

                                        aria-controls="panel3bh-content"
                                        id="panel3bh-header"
                                    >
                                        <Box display="flex" alignItems="flex-start">
                                            {expanded === 'panel3' ? <RemoveCircleIcon /> : <AddCircleIcon />}
                                            <Typography component="div" sx={{ ml: 2 }} className='text-start'>
                                                <b>Can Lean Data Governance On-Cloud be customized ?</b>
                                            </Typography>
                                        </Box>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography className='text-start d-flex justify-content-center align-items-center'>
                                            <p>
                                                As per general practice Cloud version is non customizable with the exception of the ‘Enterprise' model which can be further personalized.

                                            </p>
                                            <div>
                                                <Link to="https://www.youtube.com/watch?v=GApiNs7qkno" target='blank'>
                                                    <img style={{ width: '80px' }} src={require("../Assets/Subscription/youtube-icon-png.webp")} alt="" loading='lazy'/>
                                                </Link>
                                            </div>

                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                    <AccordionSummary

                                        aria-controls="panel4bh-content"
                                        id="panel4bh-header"
                                    >
                                        <Box display="flex" alignItems="flex-start">
                                            {expanded === 'panel4' ? <RemoveCircleIcon /> : <AddCircleIcon />}
                                            <Typography component="div" sx={{ ml: 2 }} className='text-start'>
                                                <b>Is data hosted safely within this Cloud platform ?</b>
                                            </Typography>
                                        </Box>
                                        
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    <Typography className='text-start d-flex justify-content-center align-items-center'>
                                            <p>
                                            PiLog follows all the international standards to safeguard data security. Indeed also stated within the subscription terms and conditions.
                                            </p>
                                            <div>
                                            <Link to="https://www.youtube.com/watch?v=OQX_RDNdV1s" target='blank'>
                                                <img style={{width:'80px'}} src={require("../Assets/Subscription/youtube-icon-png.webp")} alt="" loading='lazy'/>
                                                </Link>
                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                    <AccordionSummary

                                        aria-controls="panel4bh-content"
                                        id="panel4bh-header"
                                    >
                                        <Box display="flex" alignItems="flex-start">
                                            {expanded === 'panel5' ? <RemoveCircleIcon /> : <AddCircleIcon />}
                                            <Typography component="div" sx={{ ml: 2 }} className='text-start'>
                                                <b>Shall new functionality and upgrades be provided within the Cloud version ?</b>
                                            </Typography>
                                        </Box>
                                        
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    <Typography className='text-start d-flex justify-content-center align-items-center'>
                                            <p>
                                            Absolutely, PiLog shall ensure to keep enriching new functionality relevant to each of the offering as part of the subscription service.

                                            </p>
                                            <div>
                                            <Link to="https://www.youtube.com/watch?v=xU_Hoq5N35I" target='blank'>
                                                <img style={{width:'80px'}} src={require("../Assets/Subscription/youtube-icon-png.webp")} alt="" loading='lazy'/>
                                                </Link>
                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                    <AccordionSummary

                                        aria-controls="panel4bh-content"
                                        id="panel4bh-header"
                                    >
                                        <Box display="flex" alignItems="flex-start">
                                            {expanded === 'panel6' ? <RemoveCircleIcon /> : <AddCircleIcon />}
                                            <Typography component="div" sx={{ ml: 2 }} className='text-start'>
                                                <b>In case customer opts to terminate the service, how is subscription fee impacted ?</b>
                                            </Typography>
                                        </Box>
                                        
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    <Typography className='text-start d-flex justify-content-center align-items-center'>
                                            <p>
                                            In line to the subscription terms and conditions, any fees eligible for refund shall be returned within within 3 working days from cancellation.

                                            </p>
                                            <div>
                                            <Link to="https://www.youtube.com/watch?v=_MJtKSAja84" target='blank'>
                                                <img style={{width:'80px'}} src={require("../Assets/Subscription/youtube-icon-png.webp")} alt="" loading='lazy'/>
                                                </Link>
                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                            
                            </div>
                        </Col>
                        <Col>
                            <div className='faq-image'>
                                <img src={require("../Assets/Subscription/LDGSubscription-Video-Image.png")} alt="gggg" loading='lazy'/>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}
