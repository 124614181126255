import React from "react";
import { Link } from "react-router-dom";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { Container,} from "react-bootstrap";
import { Button } from "@mantine/core";
import styles from './Cta.module.css'
const CtaSection = ({header,paragraph,button,link}) => {
  return (
    <>
      <section className={styles["cta-section"]}>
        <div>
          <Container className={styles["cta-content"]}>
            <h2>{header}</h2>
            <p>
              {paragraph}
            </p>
            <Link to={link}>
              <Button
                variant="filled"
                rightSection={<ArrowOutwardIcon fontSize="20`" />}
              >
               {button}{" "}
              </Button>
            </Link>
          </Container>
        </div>
      </section>
    </>
  );
};

export default CtaSection;
