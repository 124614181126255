import { createTheme } from "@mantine/core";

export const WebTheme = createTheme({
  colors: {
    primary: ["#0b4a99"], // Define custom primary color
  },
  components: {
    Button: {
      styles: {
        root: {
          backgroundColor: "#0b4a99",
          color: "#fff",
          borderRadius: "8px", // Smooth border radius
          border: "2px solid transparent",
          transition: "all 0.3s ease",
          "&:hover": {
            backgroundColor: "#fff",
            color: "#0b4a99",
            borderColor: "#0b4a99",
          },
        },
      },
    },
  },
});
