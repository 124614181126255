import React from "react";
import "./ValueProposition.css";
import { Col, Container, Row } from "react-bootstrap";
import { Button } from "@mantine/core";
import { Link } from "react-router-dom";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

export const ValueProposition = () => {
  return (
    <div>
      <section className="Value-propositions-Section1">
        <Container className="">
          <Row style={{ alignItems: "center" }}>
            <Col lg={7}>
              <div className="Strategic-Advisory-Section1-content">
                <h1>Value Propositions</h1>
                <p>
                  A value proposition highlights the unique benefits a product
                  or service offers, explaining why it’s the best choice for
                  addressing customer needs.
                </p>
              </div>
            </Col>
            <Col lg={5}>
              <div className="rightBannersection">
                <img
                  width="100%"
                  src={require("../Assets/ValuePropositions/Value Propositions-banner-image.png")}
                  alt="Ai-hero-img"
                  loading="laxzy"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col lg={6} md={6} sm={12} xs={12}>
              <div className="Value-propositions-Section2-image">
                <img
                  className="w-100"
                  src={require("../Assets/ValuePropositions/Extebted-supplay-chain.png")}
                  alt="ldg-key-aspects"
                  loading="lazy"
                />
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <div className="Value-propositions-Section2-content">
                <div className="empty-div"></div>
                <h2>Extended Supply Chain Optimization</h2>
                <p>
                  Increasing costs and risks to right sourcing, optimal
                  procurement, and inventory with most of the industries spend
                  investment at whopping 70-75% of annual budgets. Continual
                  cost reduction drives in highly violate supply chain will be
                  critical success factor.
                </p>
                <Link to="/extended-supply">
                  <Button variant="filled" rightSection={<ArrowOutwardIcon fontSize="20`"/>}>Read more</Button>
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center">
            <Col lg={6} md={6} sm={12} xs={12}>
              <div className="Value-propositions-Section2-content">
                <div className="empty-div"></div>
                <h2>Enduring Asset Lifecycle Management</h2>
                <p>
                  Asset utilization, performance, productivity drives
                  organization efficiencies. Organizations have ZERO Appetite
                  for asset replacement or asset tracking software and more
                  automation. It’s critical to manage and monitor old warhorses
                  to perform and excel.
                </p>
                <Link to="/enduring-asset">
                  <Button variant="filled" rightSection={<ArrowOutwardIcon fontSize="20`"/>}>Read more</Button>
                </Link>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <div className="Value-propositions-Section2-image">
                <img
                  className="w-100"
                  src={require("../Assets/ValuePropositions/Enduring-Assets-Lifecycle.jpg")}
                  alt="ldg-key-aspects"
                  loading="lazy"
                />
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center">
            <Col lg={6} md={6} sm={12} xs={12}>
              <div className="Value-propositions-Section2-image">
                <img
                  className="w-100"
                  src={require("../Assets/ValuePropositions/Bussines-Life-Cycle.png")}
                  alt="ldg-key-aspects"
                  loading="lazy"
                />
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <div className="Value-propositions-Section2-content">
                <div className="empty-div"></div>
                <h2>Business Partner Life Cycle Management</h2>
                <p>
                  With ZERO appetite for asset replacement in aging industries,
                  industry leaders nightmare in enduring performance from old
                  warhorses is pretty evident.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center">
            <Col lg={6} md={6} sm={12} xs={12}>
              <div className="Value-propositions-Section2-content">
                <div className="empty-div"></div>
                <h2>Seamless Data Migration</h2>
                <p>
                  System appreciation, consolidation, and migration have been
                  buzzwords in Value Management with more and more organization
                  growing organic or inorganically. With high pressure on
                  costreduction, IT optimization, return to native, ROI and TCO
                  management becomes a daunting challenge with the change in
                  Technology.
                </p>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <div className="Value-propositions-Section2-image">
                <img
                  className="w-100"
                  src={require("../Assets/ValuePropositions/seemless-Data-Migration.jpg")}
                  alt="ldg-key-aspects"
                  loading="lazy"
                />
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center">
            <Col lg={6} md={6} sm={12} xs={12}>
              <div className="Value-propositions-Section2-image">
                <img
                  className="w-100"
                  src={require("../Assets/ValuePropositions/Digital-Transformation.png")}
                  alt="ldg-key-aspects"
                  loading="lazy"
                />
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <div className="Value-propositions-Section2-content">
                <div className="empty-div"></div>
                <h2>Digital Transformation and Automation</h2>
                <p>
                  With ZERO appetite for asset replacement in aging industries,
                  industry leaders nightmare in enduring performance from old
                  warhorses is pretty evident.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};
