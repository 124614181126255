import { Col, Row } from "react-bootstrap";
import "./leadership.css";
import { LinkedIn } from "@mui/icons-material";
import { X } from "@mui/icons-material";

const LeaderShipCards = ({ name, role, company, cols, description, image }) => {
  return (
    <>
      <div className={`${cols} leader-ship-cards-main my-4 text-start`}>
        <Row className="leader-ship-cards">
          <Col md={4} sm={12} xs={12}>
            <img
              src={image}
              alt={name}
              loading="lazy"
              className="w-100 rounded"
            />
          </Col>
          <Col
            md={7}
            sm={12}
            xs={12}
            className="leadrship-card-right text-start"
          >
            <div className="leader-name">
              <h4 className="fw-bolder mb-0">{name}</h4>
              <p>
                {role} - {company}
              </p>
            </div>
            <div className="leader-role-company mt-2">
              <p className="leader-description">{description}</p>
              {/* <p className="readmore-leader">Read More</p> */}
            </div>
            <div className="mt-2 leaders-social-icons">
              <LinkedIn />
              <X fontSize="15px" className="ms-1"/>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default LeaderShipCards;
