import { Row,  Container } from "react-bootstrap";
import { leadershipArray } from "../../constants/constants";
import LeaderShipCards from "./leadershipcards/LeaderShipCards";
import { useState } from "react";
import styles from "./leadership.module.css";

const Leadership = () => {
  return (
    <>
      <div>
        <section className={styles["leadership-hero-section"]}>
          <div>
            <Container>
              <h1 className="text-center">Leadership Team</h1>
            </Container>
          </div>
        </section>
        <section className={styles['leaders-section']}>
          <Container>
            <Row>
              {leadershipArray.map((item) => (
                <LeaderShipCards
                  key={item.id}
                  name={item.name}
                  company={item.company}
                  role={item.position}
                  region={item.region}
                  description={item.description}
                  image={item.image}
                  cols={"col-xs-12 col-md-6 col-sm-12"}
                />
              ))}
            </Row>
          </Container>
          <Container>
            <Row></Row>
          </Container>
        </section>
      </div>
    </>
  );
};

export default Leadership;
