import { Flex, Container, Box,Grid,} from "@mantine/core";
import styles from "./styles.module.css";
import ppcCement from "../../Components/Assets/industries&company/ppc-cement.svg"
import rioTinto from "../../Components/Assets/industries&company/rio-tinto.svg";
import {useRef} from "react";
import { clients } from "../../constants/constants";

const IndustryandCustomers = () => {
  const sectionRef = useRef({});

  const scroll = (sec) => {
    if (sectionRef.current[sec]) {
      sectionRef.current[sec].scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  return (
    <>
      <Box component="section">
        <Box
          p={"10rem 3rem"}
          component="section"
          className={styles.industryBanner}
        >
          <Box>
            <h1 style={{color:"#fff"}}>Industries and Customers</h1>
          </Box>
        </Box>
        <Box component="section" m={"2rem 0"}>
          <Box p={"3rem 0"}>
            <Container>
              <Flex
                wrap={"wrap"}
                gap={"6px"}
                className="text-center"
                align={"center"}
                justify={"center"}
              >
                {clients.map((name, idx) => (
                  <Box  style={{cursor:"pointer"}} className={styles.companiesNames}  fz={"md"} key={name} component="a" m={"0 3px"} onClick={() => scroll(name)}>
                    {name}
                  </Box>
                ))}
              </Flex>
            </Container>
          </Box>
        </Box>
        <Box component="section" m={"2rem 0"} ref={(el) => sectionRef.current[clients[0]] = el}>
          <Box>
            <Container>
              <h3 className="text-center"mb={"3rem"} className="text-center mb-5">Airport and Aviation</h3>
              <Box p={"3rem 0"}>
                <Grid align="center" justify="center">
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/abu-dhabi-airport-logo.jpg")}
                      alt=""
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/Al_Maktoum_Airports.png")}
                      alt=""
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Dubai-airports.png")}
                      alt=""
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/riyadh-airport-logo.jpg")}
                      alt=""
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/ESA.jpg")}
                      alt=""
                    />
                  </Grid.Col>
                </Grid>
              </Box>
            </Container>
          </Box>
        </Box>
        <Box component="section" m={"2rem 0"} ref={(el) => sectionRef.current[clients[1]] = el}>
          <Box>
            <Container className="text-center">
              <h3 className="text-center"mb={"3rem"}>Cement</h3>
              <Box p={"3rem 0"}>
                <Grid align="center" justify="center">
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/Aditya_MP_Birla20Group.jpg")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/Birla_Cement.jpg")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/Birla_White_Cement.jpg")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/nuvoco-new-logo.png")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={ppcCement}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/UltraTech.png")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/logos-110.png")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/logos-106.png")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/dalmia_logo.png")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/reliance-cement.png")}
                      alt=""
                      loading="lazy"
                      width={"50%"}
                    />
                  </Grid.Col>
                </Grid>
              </Box>
            </Container>
          </Box>
        </Box>
        <Box component="section" m={"2rem 0"} ref={(el) => sectionRef.current[clients[2]] = el}>
          <Box>
            <Container>
              <h3 className="text-center"mb={"3rem"}>Chemicals and Refineries</h3>
              <Box p={"3rem 0"}>
                <Grid align="center" justify="center">
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Arya.png")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/HZL.jpg")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/IBN_Rushd.png")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/IBN_Zdhr.jpg")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Kemya.png")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Natref.png")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Petrokemya.jpg")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Polifin.png")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Sabic.png")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Sable.png")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Sabtank.jpg")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Sadara.png")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Sahara.jpg")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/SAS.png")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Sasol.png")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Sastech.jpg")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Saudi-Kayan.jpg")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/SCI.png")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Sharq.jpg")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Sipchem.jpg")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Yansab.png")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/orpic.png")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/logos-109.png")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/technip.png")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/SCI-values.png")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/qafac_logo.jpg")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/jg_logo.png")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/petrochemical-industries-company-ksc.jpg")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                </Grid>
              </Box>
            </Container>
          </Box>
        </Box>
        <Box component="section" m={"2rem 0"} ref={(el) => sectionRef.current[clients[3]] = el}>
          <Box>
            <Container>
              <h3 className="text-center"mb={"3rem"}>Construction</h3>
              <Box p={"3rem 0"} >
                <Grid align="center" justify="center" ta={"center"}>
                  <Grid.Col spam={{ base: 12, md: 12 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/npcc.png")}
                      alt=""
                    />
                  </Grid.Col>
                </Grid>
              </Box>
            </Container>
          </Box>
        </Box>
        <Box component="section" m={"2rem 0"} ref={(el) => sectionRef.current[clients[4]] = el}>
          <Box>
            <Container>
              <h3 className="text-center"mb={"3rem"}>Defence</h3>
              <Box p={"3rem 0"} align="center" justify="center">
                <Grid>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/Client-Logos-10.jpg")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/Client-Logos-12.jpg")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/royal_commision_logo.jpg")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/SA-Airways.png")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/saaf_site_logo.jpg")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/DLA-Airforcs.png")}
                      alt=""
                      loading="lazy"
                      width={"100px"}
                    />
                  </Grid.Col>
                </Grid>
              </Box>
            </Container>
          </Box>
        </Box>
        <Box component="section" m={"2rem 0"} ref={(el) => sectionRef.current[clients[5]] = el}>
          <Box>
            <Container>
              <h3 className="text-center"mb={"3rem"}>Education</h3>
              <Box p={"3rem 0"} align="center" justify="center">
                <Grid>
                  <Grid.Col>
                    <img
                      src={require("../../Components/Assets/industries&company/GEMS_Education_new_logo_version.png")}
                      alt=""
                      width={
                        "200px"
                      }
                    />
                  </Grid.Col>
                </Grid>
              </Box>
            </Container>
          </Box>
        </Box>
        <Box component="section" m={"2rem 0"} ref={(el) => sectionRef.current[clients[6]] = el}>
          <Box>
            <Container>
              <h3 className="text-center"mb={"3rem"}>Energy, Utilities and Nuclear</h3>
              <Box p={"3rem 0"} >
                <Grid align="center" justify="center">
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/Adani.png")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/BP.png")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/Cairn.png")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/Eskom.jpg")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/Marafiq.png")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/Nomac.jpg")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/SAMSUNG_ICAD_WTP.png")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/logos-115.png")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/CLP-Ind.jpg")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/shyam_logo.png")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/NWC.jpg")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/rawec_logo.png")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/taqa_logo.jpg")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/Nawah-Energy-Company.jpg")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/Emicool.jpg")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/TAPCO.jpg")}
                      alt=""
                      loading="lazy"
                      width={"100px"}
                    />
                  </Grid.Col>
                </Grid>
              </Box>
            </Container>
          </Box>
        </Box>
        <Box component="section" m={"2rem 0"} ref={(el) => sectionRef.current[clients[7]] = el}>
          <Box>
            <Container>
              <h3 className="text-center"mb={"3rem"}>Food and Beverages</h3>
              <Box p={"3rem 0"}>
                <Grid align="center" justify="center">
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/ABI.png")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Coca_Cola.png")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Kgalagadi.png")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Swaziland.png")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Tanzania.png")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/ZB.png")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/nfl-foods.png")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/conagra-food.png")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/jsl-life.png")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/nirma.png")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/adhani-wilmar.png")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/sa-brawery.png")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                </Grid>
              </Box>
            </Container>
          </Box>
        </Box>
        <Box component="section" m={"2rem 0"} ref={(el) => sectionRef.current[clients[8]] = el}>
          <Box>
            <Container>
              <h3 className="text-center"mb={"3rem"}>Hospitality</h3>
              <Box p={"3rem 0"}>
                <Grid align="center" justify="center" ta={"center"}>
                  <Grid.Col span={{ base: 12, md: 4 }}>
                    <img
                      width={"150px"}
                      src={require("../../Components/Assets/industries&company/dubai-holding-.jpg")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 4 }}>
                    <img
                      width={"150px"}
                      src={require("../../Components/Assets/industries&company/neom_logo.png")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 4 }}>
                    <img
                      width={"150px"}
                      src={require("../../Components/Assets/industries&company/GEA-KSA.png")}
                      alt=""
                      loading="lazy"
                    />
                  </Grid.Col>
                </Grid>
              </Box>
            </Container>
          </Box>
        </Box>
        <Box component="section" m={"2rem 0"} ref={(el) => sectionRef.current[clients[9]] = el}>
          <Box>
            <Container>
              <h3 className="text-center"mb={"3rem"}>Iron and Steel</h3>
              <Box p={"3rem 0"}>
                <Grid align="center" justify="center">
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/ArcelorMittal.png")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/Bahrain_Steel.png")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/Columbus_Stainless.png")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/emrate-steel.png")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/Foulath.png")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/LOGO-22.png")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/Severstal_logo_Latinized.png")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/SULB.png")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/welspun-word-logo.png")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/jindal-steel.png")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/jsw_logo.jpg")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/vedanta_logo.png")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/sar_logo.png")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/hadeed_steel_industries_logo.jpg")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/Mittal_Steel_Company_logo.png")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/Kuwait-Steel.jpg")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/Oman-Aluminium-rolling-comapny.jpg")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/Jindal-Shadeed-logo.jpg")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                </Grid>
              </Box>
            </Container>
          </Box>
        </Box>
        <Box component="section" m={"2rem 0"} ref={(el) => sectionRef.current[clients[10]] = el}>
          <Box>
            <Container>
              <h3 className="text-center"mb={"3rem"}>Manufacture</h3>
              <Box p={"3rem 0"}>
                <Grid>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/LOGO-42.png")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/jg_logo.png")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/sankyu_logo.png")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/olayan_logo.jpg")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/tech_logo.png")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/PGP-Group.png")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                </Grid>
              </Box>
            </Container>
          </Box>
        </Box>
        <Box component="section" m={"2rem 0"} ref={(el) => sectionRef.current[clients[11]] = el}>
          <Box>
            <Container>
              <h3 className="text-center"mb={"3rem"}>Marine</h3>
              <Box p={"3rem 0"} >
                <Grid align="center" justify="center">
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/Adani.png")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/port-of-fujairah-logo.jpg")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/dp-world-logo.jpg")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={require("../../Components/Assets/industries&company/British_Aerospace.png")}
                      alt=""
                      loading="lazy"
                      width={"100%"}
                    />
                  </Grid.Col>
                </Grid>
              </Box>
            </Container>
          </Box>
        </Box>
        <Box component="section" m={"2rem 0"} ref={(el) => sectionRef.current[clients[12]] = el}>
          <Box>
            <Container>
              <h3 className="text-center"mb={"3rem"}>Mining</h3>
              <Box p={"3rem 0"}>
                <Grid align="center" justify="center">
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Angla_American.png")}
                      loading="lazy"
                      alt=""
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Anglo_Gold_Ashanti.png")}
                      loading="lazy"
                      alt=""
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Balco.png")}
                      loading="lazy"
                      alt=""
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Bhpdilliton.png")}
                      loading="lazy"
                      alt=""
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Cola_India.png")}
                      loading="lazy"
                      alt=""
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/ERG.png")}
                      loading="lazy"
                      alt=""
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Harmony.png")}
                      loading="lazy"
                      alt=""
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Harmony-Gold.png")}
                      loading="lazy"
                      alt=""
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Impala.png")}
                      loading="lazy"
                      alt=""
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/JSL.png")}
                      loading="lazy"
                      alt=""
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Lonmin.png")}
                      loading="lazy"
                      alt=""
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/MA'ADEN.png")}
                      loading="lazy"
                      alt=""
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Nordgold.png")}
                      loading="lazy"
                      alt=""
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/PMC.png")}
                      loading="lazy"
                      alt=""
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      src={
                        rioTinto
                      }
                      loading="lazy"
                      alt=""
                      width={"100%"}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Hindalco.png")}
                      loading="lazy"
                      alt=""
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Hindusthan-Zink_Logo.png")}
                      loading="lazy"
                      alt=""
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Glencore_logo.png")}
                      loading="lazy"
                      alt=""
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Richards.png")}
                      loading="lazy"
                      alt=""
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Client-Logos-14.jpg")}
                      loading="lazy"
                      alt=""
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Sasol.png")}
                      loading="lazy"
                      alt=""
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Severstal_logo_Latinized.png")}
                      loading="lazy"
                      alt=""
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Vale.png")}
                      loading="lazy"
                      alt=""
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Vedanta.png")}
                      loading="lazy"
                      alt=""
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 2 }}>
                    <img
                      width={"100%"}
                      src={require("../../Components/Assets/industries&company/Xstrata.png")}
                      loading="lazy"
                      alt=""
                    />
                  </Grid.Col>
                </Grid>
              </Box>
            </Container>
          </Box>
        </Box>
        <Box component="section" m={"2rem 0"} ref={(el) => sectionRef.current[clients[13]] = el}>
          <Box>
            <Container>
              <h3 className="text-center"mb={"3rem"}>Oil and Gas</h3>
              <Box p={"3rem 0"}>
                <Grid align="center" justify="center">
                  <Grid.Col span={{base:12,md:2}}>
                    <img  src={require("../../Components/Assets/industries&company/ADNOC.png")} width={"100%"} loading="lazy" alt=""/>
                  </Grid.Col>
                  <Grid.Col span={{base:12,md:2}}>
                    <img  src={require("../../Components/Assets/industries&company/BP.png")} width={"100%"} loading="lazy" alt=""/>
                  </Grid.Col>
                  <Grid.Col span={{base:12,md:2}}>
                    <img  src={require("../../Components/Assets/industries&company/Cairn.png")} width={"100%"} loading="lazy" alt=""/>
                  </Grid.Col>
                  <Grid.Col span={{base:12,md:2}}>
                    <img  src={require("../../Components/Assets/industries&company/CCED.png")} width={"100%"} loading="lazy" alt=""/>
                  </Grid.Col>
                  <Grid.Col span={{base:12,md:2}}>
                    <img  src={require("../../Components/Assets/industries&company/Chevron.png")} width={"100%"} loading="lazy" alt=""/>
                  </Grid.Col>
                  <Grid.Col span={{base:12,md:2}}>
                    <img  src={require("../../Components/Assets/industries&company/KJO-new.jpg")} width={"100%"} loading="lazy" alt=""/>
                  </Grid.Col>
                  <Grid.Col span={{base:12,md:2}}>
                    <img  src={require("../../Components/Assets/industries&company/Noble-Energy.png")} width={"100%"} loading="lazy" alt=""/>
                  </Grid.Col>
                  <Grid.Col span={{base:12,md:2}}>
                    <img  src={require("../../Components/Assets/industries&company/Draon-oil.jpg")} width={"100%"} loading="lazy" alt=""/>
                  </Grid.Col>
                  <Grid.Col span={{base:12,md:2}}>
                    <img  src={require("../../Components/Assets/industries&company/OQ.jpg")} width={"100%"} loading="lazy" alt=""/>
                  </Grid.Col>
                  <Grid.Col span={{base:12,md:2}}>
                    <img  src={require("../../Components/Assets/industries&company/Yasref-oil.png")} width={"100%"} loading="lazy" alt=""/>
                  </Grid.Col>
                  <Grid.Col span={{base:12,md:2}}>
                    <img  src={require("../../Components/Assets/industries&company/advanced_logo.jpg")} width={"100%"} loading="lazy" alt=""/>
                  </Grid.Col>
                  <Grid.Col span={{base:12,md:2}}>
                    <img  src={require("../../Components/Assets/industries&company/Motiva-logo.jpg")} width={"100%"} loading="lazy" alt=""/>
                  </Grid.Col>
                  <Grid.Col span={{base:12,md:2}}>
                    <img  src={require("../../Components/Assets/industries&company/bapco-energies-logo.jpg")} width={"100%"} loading="lazy" alt=""/>
                  </Grid.Col>
                  <Grid.Col span={{base:12,md:2}}>
                    <img  src={require("../../Components/Assets/industries&company/Aramco.png")} width={"100%"} loading="lazy" alt=""/>
                  </Grid.Col>
                  <Grid.Col span={{base:12,md:2}}>
                    <img  src={require("../../Components/Assets/industries&company/quater-energy-logo.jpg")} width={"100%"} loading="lazy" alt=""/>
                  </Grid.Col>
                </Grid>
              </Box>
            </Container>
          </Box>
        </Box>
        <Box component="section" m={"2rem 0"} ref={(el) => sectionRef.current[clients[14]] = el}>
          <Box>
            <Container>
              <h3 className="text-center"mb={"3rem"}>Telecommunications</h3>
              <Box p={"3rem 0"}>
                <Grid align="center" justify="center" ta={"center"}>
                  <Grid.Col span={{base:12,md:4}}>
                    <img src={require("../../Components/Assets/industries&company/LOGO-41.png")} width={"200px"} alt="" loading="lazy"/>
                  </Grid.Col>
                  <Grid.Col span={{base:12,md:4}}>
                    <img src={require("../../Components/Assets/industries&company/samsung_logo.jpg")} width={"200px"} alt="" loading="lazy"/>
                  </Grid.Col>
                  <Grid.Col span={{base:12,md:4}}>
                    <img src={require("../../Components/Assets/industries&company/MTN.png")} width={"200px"} alt="" loading="lazy"/>
                  </Grid.Col>
                </Grid>
              </Box>
            </Container>
          </Box>
        </Box>
        <Box component="section" m={"2rem 0"} ref={(el) => sectionRef.current[clients[15]] = el}>
          <Box>
            <Container>
              <h3 className="text-center"mb={"3rem"}>Textile</h3>
              <Box p={"3rem 0"}>
                <Grid align="center" justify="center">
                  <Grid.Col span={{base:12,md:4}}>
                    <img width={"200px"} src={require("../../Components/Assets/industries&company/AYM_SYNTEX.png")} alt="" loading="lazy" />
                  </Grid.Col>
                  <Grid.Col span={{base:12,md:4}}>
                    <img width={"200px"} src={require("../../Components/Assets/industries&company/Invista.png")} alt="" loading="lazy" />
                  </Grid.Col>
                  <Grid.Col span={{base:12,md:4}}>
                    <img width={"200px"} src={require("../../Components/Assets/industries&company/welspun-word-logo.png")} alt="" loading="lazy" />
                  </Grid.Col>
                </Grid>
              </Box>
            </Container>
          </Box>
        </Box>
        <Box component="section" m={"2rem 0"} ref={(el) => sectionRef.current[clients[15]] = el}>
          <Box>
            <Container>
              <h3 className="text-center"mb={"3rem"}>Transport</h3>
              <Box p={"3rem 0"}>
                <Grid align="center" justify="center" ta="center">
                  <Grid.Col span={{base:12,md:4}}>
                    <img width={"120px"} src={require("../../Components/Assets/industries&company/Transnet_logo.png")} alt="" loading="lazy" />
                  </Grid.Col>
                  <Grid.Col span={{base:12,md:4}}>
                    <img width={"120px"} src={require("../../Components/Assets/industries&company/sar_logo.png")} alt="" loading="lazy" />
                  </Grid.Col>
                </Grid>
              </Box>
            </Container>
          </Box>
        </Box>
        <Box component="section" m={"2rem 0"} ref={(el) => sectionRef.current[clients[17]] = el}>
          <Box>
            <Container></Container>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default IndustryandCustomers;
