import {
  IconBook,
  IconChartPie3,
  IconFileText,
  IconCalendar,
  IconMapPin,
  IconUsers,
  IconAdjustments,
  IconChartBar,
  IconServer,
  IconDatabase,
  IconTool,
  IconMessageDots,
  IconServer2,
  IconCloud,
  IconArrowUpRight,
  IconBriefcase,
  IconClipboard,
  IconPhoto,
  IconSearch,
  IconSettings,
  IconAlertCircle,
  IconClipboardCheck,
  IconTrendingUp,
  IconTruck,
  IconShoppingCart,
  IconPhone,
  IconChevronDown,
  IconChecklist,
  IconEye,
  IconDeviceAnalytics,
  IconFlame,
  IconBuildingFactory2,
  IconFlask,
  IconBolt,
  IconTruckDelivery,
  IconPlaneDeparture,
  IconBook2,
  IconMountain,
  IconSoup,
  IconHotelService,
  IconAntennaBars5,
} from "@tabler/icons-react";
import {
  Flex,
  Box,
  Burger,
  Button,
  Center,
  Divider,
  Drawer,
  Group,
  HoverCard,
  ScrollArea,
  SimpleGrid,
  Text,
  ThemeIcon,
  UnstyledButton,
  Grid,
  Container,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import classes from "./megamenu.module.css";
import { Link } from "react-router-dom";
import { useState } from "react";

// const innovativeData = [
//   {
//     title: "Lean Data Governance",
//     link: "lean-data-governance",
//     description: "Streamline data policies and compliance.",
//     icon: IconSettings,
//     img: require("../Assets/LDG/LDG_HomePage-Image.png"),
//   },
//   {
//     title: "Intelligent Data Quality Management",
//     link: "iDQM",
//     description: "AI-driven data validation and quality improvement.",
//     icon: IconChecklist,
//     img: require("../Assets/iDQM/iDQM.png"),
//   },
//   {
//     title: "AI Lens",
//     link: "ai-lens",
//     description: "Advanced AI-powered analytics and insights.",
//     icon: IconEye,
//     img: require("../Assets/AiLens/AILens.png"),
//   },
//   {
//     title: "iContent Foundry",
//     link: "content-foundary",
//     description: "Automated content creation and management.",
//     icon: IconFileText,
//     img: require("../Assets/AiLens/AILens.png"),
//   },
//   {
//     title: "Smart IG",
//     link: "https://smartintegraphics.com/",
//     description: "Smart Interactive Graphics for better data visualization.",
//     icon: IconDeviceAnalytics,
//     img: require("../Assets/AiLens/AILens.png"),
//   },
//   {
//     title: "SAP Apps",
//     link: "ai-lens",
//     description: "Custom SAP applications for business automation.",
//     icon: IconServer,
//     img: require("../Assets/AiLens/AILens.png"),
//   },
// ];

// const servicesData = [
//   {
//     title: "Enterprise Data Management",
//     link: "#",
//     description:
//       "Comprehensive solutions for managing enterprise data efficiently.",
//     icon: IconDatabase,
//   },
//   {
//     title: "EAM Practice",
//     link: "#",
//     description:
//       "Enterprise Asset Management practices to optimize asset lifecycle.",
//     icon: IconTool,
//   },
//   {
//     title: "SAP Services",
//     link: "#",
//     description: "End-to-end SAP solutions to drive business transformation.",
//     icon: IconServer2,
//   },
//   {
//     title: "Advisory Services",
//     link: "#",
//     description: "Expert consulting services for strategic business growth.",
//     icon: IconMessageDots,
//   },
//   {
//     title: "Rise with SAP",
//     link: "#",
//     description:
//       "A cloud-based solution to accelerate business transformation.",
//     icon: IconCloud,
//   },
//   {
//     title: "Grow with SAP",
//     link: "#",
//     description: "SAP solutions tailored to support business scalability.",
//     icon: IconArrowUpRight,
//   },
// ];

const menuData = [
  {
    title: "About Us",
    link: "/about-us",
  },
  {
    title: "Innovative Products",
    items: [
      {
        heading: "PiLog's Data Quality and Governance Suite",
        industries: [
          {
            title: "Intelligent Data Quality Management - IDQM",
            link: "iDQM",
            description: "Data validation and quality improvement.",
            icon: IconChecklist,
            img: require("../Assets/iDQM/iDQM.png"),
          },
          {
            title: "iContent Foundry - iCF",
            link: "content-foundary",
            description: "Automated content creation and management.",
            icon: IconFileText,
            img: require("../Assets/AiLens/AILens.png"),
          },
          {
            title: "Lean Data Governance - LDG",
            link: "lean-data-governance",
            description: "Streamline data policies and compliance.",
            icon: IconSettings,
            img: require("../Assets/LDG/LDG_HomePage-Image.png"),
          },
          {
            title: "AI Lens",
            link: "ai-lens",
            description: "Co-pilot/Conversational AI",
            icon: IconEye,
            img: require("../Assets/AiLens/AILens.png"),
          },
          {
            title: "Add ons",
            link: "ai-lens",
            description:
              "Material Master Taxonomy for SAP MDG & Service Master Taxonomy for SAP MDG",
            icon: IconEye,
            img: require("../Assets/AiLens/AILens.png"),
          },
        ],
      },
      // {
      //   heading: "Add on",
      //   industries: [
      //     {
      //       title: "Material Master Taxonomy",
      //       link: "iDQM",
      //       description: "Data validation and quality improvement.",
      //       icon: IconChecklist,
      //       img: require("../Assets/iDQM/iDQM.png"),
      //     },
      //     {
      //       title: "Service Master Taxonomy",
      //       link: "iDQM",
      //       description: "Data validation and quality improvement.",
      //       icon: IconChecklist,
      //       img: require("../Assets/iDQM/iDQM.png"),
      //     },
      //   ],
      // },
      {
        industries: [
          {
            title: "SmartIG",
            link: "https://smartintegraphics.com/",
            description:
              "Smart Interactive Graphics for better data visualization.",
            icon: IconDeviceAnalytics,
            img: require("../Assets/AiLens/AILens.png"),
          },
          {
            title: "SAP Apps",
            link: "ai-lens",
            description: "Custom SAP applications for business automation.",
            icon: IconServer,
            img: require("../Assets/AiLens/AILens.png"),
            subItems: [
              "Material Master Taxonomy for SAP Master Data Governance",
              "Material Master Taxonomy for SAP Master Data Governance",
            ],
          },
          {
            title: "iMDRM",
            link: "iDQM",
            description: "Data validation and quality improvement.",
            icon: IconChecklist,
            img: require("../Assets/iDQM/iDQM.png"),
          },
        ],
      },
      {
        industries: [
          {
            title: "iFAR",
            link: "https://smartintegraphics.com/",
            description:
              "Smart Interactive Graphics for better data visualization.",
            icon: IconDeviceAnalytics,
            img: require("../Assets/AiLens/AILens.png"),
          },
          {
            title: "iSPIR",
            link: "ai-lens",
            description: "Custom SAP applications for business automation.",
            icon: IconServer,
            img: require("../Assets/AiLens/AILens.png"),
            subItems: [
              "Material Master Taxonomy for SAP Master Data Governance",
              "Material Master Taxonomy for SAP Master Data Governance",
            ],
          },
          {
            title: "Coswin",
            link: "iDQM",
            description: "Data validation and quality improvement.",
            icon: IconChecklist,
            img: require("../Assets/iDQM/iDQM.png"),
          },
        ],
      },
    ],
  },
  {
    title: "Services",
    items: [
      {
        title: "Enterprise Data Management",
        link: "#",
        description:
          "Comprehensive solutions for managing enterprise data efficiently.",
        icon: IconDatabase,
      },
      {
        title: "EAM Practice",
        link: "#",
        description:
          "Enterprise Asset Management practices to optimize asset lifecycle.",
        icon: IconTool,
      },
      {
        title: "SAP Services",
        link: "#",
        description:
          "End-to-end SAP solutions to drive business transformation.",
        icon: IconServer2,
      },
      {
        title: "Advisory Services",
        link: "/strategic-advisory",
        description:
          "Expert consulting services for strategic business growth.",
        icon: IconMessageDots,
      },
      {
        title: "Rise with SAP",
        link: "/rise-with-sap",
        description:
          "A cloud-based solution to accelerate business transformation.",
        icon: IconCloud,
      },
      {
        title: "Grow with SAP",
        link: "#",
        description: "SAP solutions tailored to support business scalability.",
        icon: IconArrowUpRight,
      },
    ],
  },
  {
    title: "Industries",
    items: [
      {
        heading: "Main Industries",
        industries: [
          {
            title: "Oil and Gas",
            description:
              "Streamlined data solutions for efficient exploration, production, and asset management.",
            link: "/oil-and-gas-industries",
            icon: IconFlame,
          },
          {
            title: "Manufacturing",
            description:
              "Boosting production quality and reducing downtime with accurate, real-time data insights.",
            icon: IconBuildingFactory2,
          },
          {
            title: "Petrochemical",
            description:
              "Optimizing supply chain and maintenance with reliable data governance practices.",
            icon: IconFlask,
          },
          {
            title: " Utilities",
            description:
              "Driving efficiency in power generation and distribution with robust data management.",
            icon: IconBolt,
          },
          {
            title: "Transportation",
            description:
              "Enhancing logistics, fleet management, and operational efficiency through accurate data.",
            icon: IconTruckDelivery,
          },
          {
            title: "Airport and Aviation",
            description:
              "Ensuring compliance, safety, and efficiency with precise asset and operations data.",
            icon: IconPlaneDeparture,
          },
        ],
      },
      {
        industries: [
          {
            title: "Education",
            description:
              "Supporting digital transformation with data-driven insights for academic institutions.",
            icon: IconBook2,
          },
          {
            title: "Mining",
            description:
              "Maximizing resource utilization and safety compliance with reliable asset data.",
            icon: IconMountain,
          },
          {
            title: "Food and Beverages",
            description:
              " Improving supply chain traceability and quality control with accurate master data.",
            icon: IconSoup,
          },
          {
            title: "Hospitality",
            description:
              "Enhancing guest experiences and operational efficiency through accurate data insights.",
            icon: IconHotelService,
          },
          {
            title: "Telecommunications",
            description:
              "Enhancing service delivery and customer experience with optimized data governance.",
            icon: IconAntennaBars5,
          },
        ],
      },
    ],
  },
  {
    title: "Value Engineering",
    items: [
      {
        title: "Enterprise Data Management",
        link: "value-proposition",
        description:
          "Centralized management of data assets for business efficiency.",
        icon: IconDatabase,
      },
      {
        title: "Supply Chain Management",
        link: "#",
        description: "Streamlining procurement, logistics, and contracts.",
        icon: IconTruck,
      },
      {
        title: "Sourcing, Category & Spend Management",
        link: "#",
        description: "Optimizing spend, sourcing, and supplier relations.",
        icon: IconShoppingCart,
      },
      {
        title: "Data Quality Management",
        link: "#",
        description: "Ensuring accurate, consistent, and reliable data.",
        icon: IconChecklist,
      },
      {
        title: "Data Governance & Analytics",
        link: "#",
        description: "Managing data policies and generating insights.",
        icon: IconChartPie3,
      },
      {
        title: "Asset Lifecycle Management",
        link: "/enduring-asset",
        description:
          "Managing assets from acquisition to disposal efficiently.",
        icon: IconTool,
      },
    ],
  },
  { title: "Contact Us", link: "/contact", icon: IconPhone },
];

function ProductItem({ icon, title, description, link }) {
  return (
    <Link to={link}>
      <UnstyledButton>
        <Group wrap="nowrap" align="center">
          <ThemeIcon size={34} variant="default" radius="md">
            {icon}
          </ThemeIcon>
          <div>
            <Text size="sm" fw={500}>
              {title}
            </Text>
            <Text size="xs" c="dimmed">
              {description}
            </Text>
          </div>
        </Group>
      </UnstyledButton>
    </Link>
  );
}

function MegaMenu() {
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const [previewImage, setPreviewImage] = useState(null);

  const onHoverImage = (img) => setPreviewImage(img);
  const removeImage = () => setPreviewImage(null);

  return (
    <Box>
      <header className={classes.header}>
        <Flex align={"center"} justify="space-between">
          <Link to="/">
            <img
              width={90}
              src={require("../Assets/Header/pilog-logo.png")}
              alt="pilog-logo"
              loading="lazy"
            />
          </Link>
          <Group m={"0 auto"} h="100%" gap={8} visibleFrom="md" wrap="nowrap">
            <Link to="/">
              <Center inline>
                <Box component="div" style={{"cursor":"pointer"}} mr={5}>
                  About Us
                </Box>
              </Center>
            </Link>
            {/* Innovative Products Section */}
            <HoverCard
              width="99%"
              radius="md"
              shadow="md"
              withinPortal
              m={"0 10px"}
            >
              <HoverCard.Target>
                <Center inline>
                  <Box component="div" style={{"cursor":"pointer"}} mr={5}>
                    Innovative Products
                  </Box>
                </Center>
              </HoverCard.Target>
              <HoverCard.Dropdown style={{ overflow: "hidden" }} pb="3rem">
                <Container size="xl">
                  {/* First Section */}
                  <Group justify="space-between" px="md">
                    <Text fw={500}>
                      PiLog's Data Quality and Governance Suite
                    </Text>
                  </Group>
                  <Divider my="sm" />
                  <SimpleGrid cols={2} spacing="xl" verticalSpacing="lg">
                    <ProductItem
                      title="Intelligent Data Quality Management - IDQM"
                      link="/idqm"
                      description="Data validation and quality improvement."
                      icon={<IconChecklist />}
                    />
                    <ProductItem
                      title="iContent Foundry - iCF"
                      link="/content-foundary"
                      description="Automated content creation and management."
                      icon={<IconFileText />}
                    />
                    <ProductItem
                      title="Lean Data Governance - LDG"
                      link="/lean-data-governance"
                      description="Streamline data policies and compliance."
                      icon={<IconSettings />}
                    />
                    <ProductItem
                      title="AI Lens"
                      link="/ai-lens"
                      description="Co-pilot/Conversational AI"
                      icon={<IconEye />}
                    />
                    <ProductItem
                      title="SmartIG"
                      link="https://smartintegraphics.com/"
                      description="Smart Interactive Graphics for better data visualization."
                      icon={<IconDeviceAnalytics />}
                    />
                    <ProductItem
                      title="SAP Apps"
                      link="/sap-apps"
                      description="Custom SAP applications for business automation."
                      icon={<IconServer />}
                    />
                  </SimpleGrid>

                  <Divider size="md" my="md" />

                  {/* Second Section */}
                  <SimpleGrid cols={3} spacing="xl" verticalSpacing="lg">
                    <ProductItem
                      icon={<IconDeviceAnalytics size={34} />}
                      title="SmartIG"
                      description="Better data visualization."
                    />
                    <ProductItem
                      icon={<IconServer size={34} />}
                      title="SAP Apps"
                      description="Custom SAP applications for business automation."
                    />
                    <ProductItem
                      icon={<IconChecklist size={34} />}
                      title="iMDRM"
                      description="Data validation and quality improvement."
                    />
                  </SimpleGrid>

                  <Divider size="md" my="md" />

                  {/* Third Section */}
                  <SimpleGrid cols={3} spacing="xl" verticalSpacing="lg">
                    <ProductItem
                      icon={<IconDeviceAnalytics size={34} />}
                      title="iFAR"
                      description="Better data visualization."
                    />
                    <ProductItem
                      icon={<IconServer size={34} />}
                      title="iSPIR"
                      description="Custom SAP applications for business automation."
                    />
                    <ProductItem
                      icon={<IconChecklist size={34} />}
                      title="Coswin"
                      description="Data validation and quality improvement."
                    />
                  </SimpleGrid>
                </Container>
              </HoverCard.Dropdown>
            </HoverCard>
            {/* Services Section */}
            <HoverCard
              width="99%"
              radius="md"
              shadow="md"
              withinPortal
              m={"0 10px"}
            >
              <HoverCard.Target>
                <Center inline>
                  <Box component="div" style={{"cursor":"pointer"}} mr={5}>
                    Services
                  </Box>
                 
                </Center>
              </HoverCard.Target>
              <HoverCard.Dropdown style={{ overflow: "hidden" }} pb="3rem">
                <Container size="xl">
                  <Group justify="space-between" px="md">
                    <Text fw={500}>Our Services</Text>
                  </Group>
                  <Divider my="sm" />
                  <SimpleGrid cols={2} spacing="xl" verticalSpacing="lg">
                    <ProductItem
                      title="Enterprise Data Management"
                      link="#"
                      description="Comprehensive solutions for managing enterprise data efficiently."
                      icon={<IconDatabase />}
                    />
                    <ProductItem
                      title="EAM Practice"
                      link="#"
                      description="Enterprise Asset Management practices to optimize asset lifecycle."
                      icon={<IconTool />}
                    />
                    <ProductItem
                      title="SAP Services"
                      link="#"
                      description="End-to-end SAP solutions to drive business transformation."
                      icon={<IconServer2 />}
                    />
                    <ProductItem
                      title="Advisory Services"
                      link="/strategic-advisory"
                      description="Expert consulting services for strategic business growth."
                      icon={<IconMessageDots />}
                    />
                    <ProductItem
                      title="Rise with SAP"
                      link="/rise-with-sap"
                      description="A cloud-based solution to accelerate business transformation."
                      icon={<IconCloud />}
                    />
                    <ProductItem
                      title="Grow with SAP"
                      link="#"
                      description="SAP solutions tailored to support business scalability."
                      icon={<IconArrowUpRight />}
                    />
                  </SimpleGrid>
                  <Divider size="md" my="md" />
                  <SimpleGrid cols={3} spacing="xl" verticalSpacing="lg">
                    <ProductItem
                      icon={<IconDatabase size={22} />}
                      title="Intelligent Data Quality Management - IDQM"
                      description="Data validation and quality improvement."
                    />
                    <ProductItem
                      icon={<IconChartBar size={22} />}
                      title="Data Health Assessment"
                      description="Assessing and improving the health of enterprise data."
                    />
                    <ProductItem
                      icon={<IconAdjustments size={22} />}
                      title="Data Harmonization"
                      description="Standardizing and integrating data across systems."
                    />
                    <ProductItem
                      icon={<IconBriefcase size={22} />}
                      title="Business Process Outsourcing"
                      description="Outsourcing business processes for efficiency."
                    />
                    <ProductItem
                      icon={<IconClipboard size={22} />}
                      title="PM Data Acquisition"
                      description="Acquiring and managing preventive maintenance data."
                    />
                    <ProductItem
                      icon={<IconPhoto size={22} />}
                      title="Imaging, Tagging, Inventory Optimization"
                      description="Optimizing inventory with imaging and tagging."
                    />
                    <ProductItem
                      icon={<IconSearch size={22} />}
                      title="Physical Verification/Walk down"
                      description="On-site verification and asset walk downs."
                    />
                    <ProductItem
                      icon={<IconSettings size={22} />}
                      title="Digital Transformation"
                      description="Transforming business with digital solutions."
                    />
                    <ProductItem
                      icon={<IconAlertCircle size={22} />}
                      title="Material Critical Analysis"
                      description="Analyzing critical materials for better management."
                    />
                    <ProductItem
                      icon={<IconClipboardCheck size={22} />}
                      title="Equipment Criticality Analysis"
                      description="Determining the criticality of equipment assets."
                    />
                    <ProductItem
                      icon={<IconTrendingUp size={22} />}
                      title="Spend Analytics"
                      description="Analyzing spending patterns for cost optimization."
                    />
                  </SimpleGrid>
                </Container>
              </HoverCard.Dropdown>
            </HoverCard>
            {/*Industries  Section */}
            <HoverCard
              width="99%"
              radius="md"
              shadow="md"
              withinPortal
              m={"0 10px"}
            >
              <HoverCard.Target>
                <Center inline>
                  <Box component="div" style={{"cursor":"pointer"}} mr={5}>
                    Industries
                  </Box>
                 
                </Center>
              </HoverCard.Target>
              <HoverCard.Dropdown style={{ overflow: "hidden" }} pb="3rem">
                <Container size="xl">
                  <Group justify="space-between" px="md">
                    <Text fw={500}>Industries We Serve</Text>
                  </Group>
                  <Divider my="sm" />

                  {/* Main Industries */}
                  <SimpleGrid cols={2} spacing="xl" verticalSpacing="lg">
                    <ProductItem
                      title="Oil and Gas"
                      link="/oil-and-gas-industries"
                      description="Streamlined data solutions for efficient exploration, production, and asset management."
                      icon={<IconFlame />}
                    />
                    <ProductItem
                      title="Manufacturing"
                      link="#"
                      description="Boosting production quality and reducing downtime with accurate, real-time data insights."
                      icon={<IconBuildingFactory2 />}
                    />
                    <ProductItem
                      title="Petrochemical"
                      link="#"
                      description="Optimizing supply chain and maintenance with reliable data governance practices."
                      icon={<IconFlask />}
                    />
                    <ProductItem
                      title="Utilities"
                      link="#"
                      description="Driving efficiency in power generation and distribution with robust data management."
                      icon={<IconBolt />}
                    />
                    <ProductItem
                      title="Transportation"
                      link="#"
                      description="Enhancing logistics, fleet management, and operational efficiency through accurate data."
                      icon={<IconTruckDelivery />}
                    />
                    <ProductItem
                      title="Airport and Aviation"
                      link="#"
                      description="Ensuring compliance, safety, and efficiency with precise asset and operations data."
                      icon={<IconPlaneDeparture />}
                    />
                    <ProductItem
                      title="Mining"
                      link="#"
                      description="Maximizing resource utilization and safety compliance with reliable asset data."
                      icon={<IconMountain />}
                    />
                    <ProductItem
                      title="Refinery"
                      link="#"
                      description=""
                      icon={<IconMountain />}
                    />
                  </SimpleGrid>

                  <Divider size="md" my="md" />

                  {/* Other Industries */}
                  <SimpleGrid cols={2} spacing="xl" verticalSpacing="lg">
                    <ProductItem
                      icon={<IconBook2 size={22} />}
                      title="Education"
                      description="Supporting digital transformation with data-driven insights for academic institutions."
                    />
                    <ProductItem
                      icon={<IconSoup size={22} />}
                      title="Food and Beverages"
                      description="Improving supply chain traceability and quality control with accurate master data."
                    />
                    <ProductItem
                      icon={<IconHotelService size={22} />}
                      title="Hospitality"
                      description="Enhancing guest experiences and operational efficiency through accurate data insights."
                    />
                    <ProductItem
                      icon={<IconAntennaBars5 size={22} />}
                      title="Telecommunications"
                      description="Enhancing service delivery and customer experience with optimized data governance."
                    />
                  </SimpleGrid>
                </Container>
              </HoverCard.Dropdown>
            </HoverCard>
            {/* Value Engineering  Section */}
            <HoverCard
              width="99%"
              radius="md"
              shadow="md"
              withinPortal
              m={"0 10px"}
            >
              <HoverCard.Target>
                <Center inline>
                  <Box component="div" style={{"cursor":"pointer"}} mr={5}>
                    Value Engineering
                  </Box>
                 
                </Center>
              </HoverCard.Target>
              <HoverCard.Dropdown style={{ overflow: "hidden" }} pb="3rem">
                <Container size="xl">
                  <Group justify="space-between" px="md">
                    <Text fw={500}>Value Engineering</Text>
                  </Group>
                  <Divider my="sm" />
                  <SimpleGrid cols={2} spacing="xl" verticalSpacing="lg">
                    <ProductItem
                      title="Enterprise Data Management"
                      link="/value-proposition"
                      description="Centralized management of data assets for business efficiency."
                      icon={<IconDatabase />}
                    />
                    <ProductItem
                      title="Supply Chain Management"
                      link="#"
                      description="Streamlining procurement, logistics, and contracts."
                      icon={<IconTruck />}
                    />
                    <ProductItem
                      title="Sourcing, Category & Spend Management"
                      link="#"
                      description="Optimizing spend, sourcing, and supplier relations."
                      icon={<IconShoppingCart />}
                    />
                    <ProductItem
                      title="Data Quality Management"
                      link="#"
                      description="Ensuring accurate, consistent, and reliable data."
                      icon={<IconChecklist />}
                    />
                    <ProductItem
                      title="Data Governance & Analytics"
                      link="#"
                      description="Managing data policies and generating insights."
                      icon={<IconChartPie3 />}
                    />
                    <ProductItem
                      title="Asset Lifecycle Management"
                      link="/enduring-asset"
                      description="Managing assets from acquisition to disposal efficiently."
                      icon={<IconTool />}
                    />
                  </SimpleGrid>
                </Container>
              </HoverCard.Dropdown>
            </HoverCard>
            <Link to="/Contact">Contact Us</Link>
          </Group>
          <Burger
            opened={drawerOpened}
            onClick={toggleDrawer}
            hiddenFrom="md"
          />
          <Group justify="center" visibleFrom="md">
            <Link to="https://calendly.com/customerdemos/ldg?month=2024-09">
              <Button leftSection={<IconCalendar />}>Book a Demo</Button>
            </Link>
          </Group>
        </Flex>
      </header>

      {/* Mobile Drawer */}
      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="md"
        padding="md"
        title="Menu"
        hiddenFrom="md"
        zIndex={1000000}
      >
        <ScrollArea h={`calc(100vh - 60px)`} mx="xs"></ScrollArea>
      </Drawer>
    </Box>
  );
}

export default MegaMenu;
