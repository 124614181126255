import { Grid, Flex, Box, Container, List, ListItem } from "@mantine/core";
import styles from "./valueengineer.module.css";

const ValueEngineer = () => {
  return (
    <>
      <Flex direction={"column"} clasName={styles.valueEnggContainer}>
        <section className={styles.valueengHerosection}>
          <Box>
              <Box p={"7rem 0"}>
                <Container className="text-center">
                  <h1 className={styles.heroHeader}>Value Engineering</h1>
                  <p className="fs-5">
                    A value proposition highlights the unique benefits a product
                    or service offers, explaining why it’s the best choice for
                    addressing customer needs
                  </p>
                </Container>
              </Box>
          </Box>
        </section>
        <section className={styles.valueEnggAssetLifeCycle}>
          <Box p={"8rem 0"}>
            <Box>
              <Container>
                <Grid>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <h2 className={styles.valueEnggCommonHeader}>Asset Lifecycle Management</h2>
                    <p className="my-4">
                      Asset utilization, performance, productivity drives
                      organization efficiencies. Organizations have ZERO
                      Appetite for asset replacement or asset tracking software
                      and more automation. It s critical to manage and monitor
                      old warhorses to perform and excel.
                    </p>
                    <List>
                      <ListItem>
                        <p>EAM Objects: Floc, Eqpt, eBoM, fBoM, MI, TL, MP</p>
                      </ListItem>
                      <ListItem>
                        <p>Capital Projects Management [EPC: SPDP + iSPIR]</p>
                      </ListItem>
                      <ListItem>
                        <p>Plant Maintenance Strategy</p>
                      </ListItem>
                      <ListItem>
                        <p>Financial Assets Management</p>
                      </ListItem>
                      <ListItem>
                        <p>Facilities Management</p>
                      </ListItem>
                    </List>
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <img
                      src={require("../../Components/Assets/ValueEngineers/Enduring-Asset-Lifecycle-Management_Image.png")}
                      alt=""
                      className="w-100"
                    />
                  </Grid.Col>
                </Grid>
              </Container>
            </Box>
          </Box>
        </section>
        <section className={styles.valueEnggSupplyChain}>
          <Box p={"8rem 0"}>
            <Box>
              <Container>
                <Grid>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <img
                      src={require("../../Components/Assets/ValueEngineers/Extended-Supply-Chain-Optimization-Img1.png")}
                      alt=""
                      className="w-100"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <h2 className={styles.valueEnggCommonHeader}>Supply Chain Management</h2>
                    <p className="my-4">
                      Increasing costs and risks to right sourcing, optimal
                      procurement, and inventory with most of the industries
                      spend investment at whopping 70-75% of annual budgets.
                      Continual cost reduction drives in highly violate supply
                      chain will be critical success factor.
                    </p>
                    <List>
                      <ListItem>
                        <p>MRO Inventory Optimization</p>
                      </ListItem>
                      <ListItem>
                        <p>Services & Contracts Management [S2C]</p>
                      </ListItem>
                      <ListItem>
                        <p>
                          S2P, P2I, S2C, A2R, O2C: Business Process Optimization
                        </p>
                      </ListItem>
                      <ListItem>
                        <p>
                          Digital Transformation & Robotic Process Orchestration
                        </p>
                      </ListItem>
                    </List>
                  </Grid.Col>
                </Grid>
              </Container>
            </Box>
          </Box>
        </section>
        <section className={styles.valueEnggEnterprise}>
          <Box p={"8rem 0"}>
            <Box>
              <Container>
                <Grid>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <h2 className={styles.valueEnggCommonHeader}>Enterprise Data Management</h2>
                    <p className="my-4">
                      Asset utilization, performance, productivity drives
                      organization efficiencies. Organizations have ZERO
                      Appetite for asset replacement or asset tracking software
                      and more automation. It s critical to manage and monitor
                      old warhorses to perform and excel.
                    </p>
                    <List>
                      <ListItem>Data Quality Foundry</ListItem>
                      <ListItem>Enterprise Data/AI Strategy</ListItem>
                      <ListItem>Data/AI Advisory</ListItem>
                      <ListItem>Data Migration / Upgrades</ListItem>
                    </List>
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <img
                      src={require("../../Components/Assets/ValueEngineers/Enterprise-Data-Management_Image2.png")}
                      alt=""
                      className="w-100"
                    />
                  </Grid.Col>
                </Grid>
              </Container>
            </Box>
          </Box>
        </section>
        <section className={styles.valueEnggSourcing}>
          <Box p={"8rem 0"}>
            <Box>
              <Container>
                <Grid>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <img
                      src={require("../../Components/Assets/ValueEngineers/Sourcing,-Category-and-Spend-Management_Image.png")}
                      alt=""
                      className="w-100"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <h2 className={styles.valueEnggCommonHeader}>Sourcing, Category and Spend Management</h2>
                    <p className="my-4">
                      Increasing costs and risks to right sourcing, optimal
                      procurement, and inventory with most of the industries
                      spend investment at whopping 70-75% of annual budgets.
                      Continual cost reduction drives in highly violate supply
                      chain will be critical success factor
                    </p>
                    <List>
                      <ListItem>
                        <p>
                          Spend and Category Management, Predictive and
                          Strategic Procurement
                        </p>
                      </ListItem>
                      <ListItem>
                        <p>
                          Data Quality Hub: Orchestration, Consolidation and
                          Reconciliation
                        </p>
                      </ListItem>
                      <ListItem>
                        <p>
                          BP Data Regulatory, Statutory Compliance and Cross
                          functional Governance
                        </p>
                      </ListItem>
                      <ListItem>
                        <p>
                          BP Data Classification, Categorization, Industry
                          Clusters
                        </p>
                      </ListItem>
                    </List>
                  </Grid.Col>
                </Grid>
              </Container>
            </Box>
          </Box>
        </section>
        <section className={styles.valueEnggDataQuality}>
          <Box p={"8rem 0"}>
            <Box>
              <Container>
                <Grid>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <h2 className={styles.valueEnggCommonHeader}>Data Quality Management</h2>
                    <p className="my-4">
                      Asset utilization, performance, productivity drives
                      organization efficiencies. Organizations have ZERO
                      Appetite for asset replacement or asset tracking software
                      and more automation. It s critical to manage and monitor
                      old warhorses to perform and excel.
                    </p>
                    <List>
                      <ListItem>
                        <p>Data Quality Compliance, Data Maturity Audit</p>
                      </ListItem>
                      <ListItem>
                        <p>
                          ISO: Meta Data, Taxonomy, Ontologies Assessment NATO,
                          eCl@ss, PIDX, eOTD, PPO, ISIC, HS etc.
                        </p>
                      </ListItem>
                      <ListItem>
                        <p>Data Cleansing, Classification & Enrichment</p>
                      </ListItem>
                    </List>
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <img
                      src={require("../../Components/Assets/ValueEngineers/Data-Quality-Management_Image.png")}
                      className="w-100"
                      alt=""
                    />
                  </Grid.Col>
                </Grid>
              </Container>
            </Box>
          </Box>
        </section>
        <section className={styles.valueEnggDataGovernace}>
          <Box p={"8rem 0"}>
            <Box>
              <Container>
                <Grid>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <img
                      src={require("../../Components/Assets/ValueEngineers/Data-Governance-&-Analytics_Image.png")}
                      className="w-100"
                      alt=""
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <h2 className={styles.valueEnggCommonHeader}>Data Governance & Analytics</h2>
                    <p className="my-4">
                      Increasing costs and risks to right sourcing, optimal
                      procurement, and inventory with most of the industries
                      spend investment at whopping 70-75% of annual budgets.
                      Continual cost reduction drives in highly violate supply
                      chain will be critical success factor.
                    </p>
                    <List>
                      <ListItem>
                        <p>MDM: AMR/EMR, MMR, SMR, VMR, EAM etc</p>
                      </ListItem>
                      <ListItem>
                        <p>Single Version of Truth</p>
                      </ListItem>
                      <ListItem>
                        <p>Integration, Orchestration Business Services</p>
                      </ListItem>
                      <ListItem>
                        <p>Descriptive, Prescriptive & Predictive Analytics</p>
                      </ListItem>
                      <ListItem>
                        <p>On Cloud & On Premise</p>
                      </ListItem>
                    </List>
                  </Grid.Col>
                </Grid>
              </Container>
            </Box>
          </Box>
        </section>
      </Flex>
    </>
  );
};

export default ValueEngineer;
