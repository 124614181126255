
import { Container, Row, Col,Card} from "react-bootstrap";
import styles from "./icontentFoundry.module.css";
import clsx from "clsx";
import { Link } from "react-router-dom";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { Button } from "@mantine/core";


const IcontenFoundry = () => {
  return (
    <>

    <section>
    <Container className="py-5">
    <Row className="align-items-center">
    <Col md={6} className="mb-4 mb-md-0 icontent-main-banner">
    <div className={styles["icontent-main-banner"]}>
    <h1 >
            The Ultimate Global Repository for  ISO-Compliant Industrial Data
          </h1>
          <p className="text-muted">
            Ensure accurate, standardized, and ISO-compliant enterprise data to optimize procurement, asset management, and supply chain operations.
          </p>
    </div>
          
        </Col>
        <Col lg={6}>
        <img loading="lazy" className="w-100" src={require("../../Components/Assets/icontent-foundry/iContent-Foundry-Img1.png")} alt="icon content found"/>
        </Col>
    </Row>
      </Container>
    </section>
      <section className={styles["icontentFoundary-hero-section"]}>
        <div className={styles["icontentFoundary-inner"]}>
          <Container>
            <h2>The Future of Enterprise Data Starts Here!</h2>
            <p>
            Say goodbye to inconsistent, unreliable data. PiLog’s iContent Foundry (iCF) is your AI-powered, ISO-compliant content repository built to supercharge enterprise data management. Designed for Oil & Gas, Petrochemicals, Mining, Manufacturing, Utilities, Transport, and more, iCF ensures data precision, compliance, and efficiency, seamlessly integrating with SAP solutions for procurement, supply chain, asset management, and operations.
            </p>
          </Container>
        </div>
      </section>
      
      <section>
        <div>
          <Container>
            <Row className="align-items-center">
              <Col xs={12} md={6} lg={6}>
                <img loading="lazy"
                  className="w-100"
                  src={require("../../Components/Assets/icontent-foundry/Value-Levers.png")}
                  alt=""
                  
                />
              </Col>
              <Col xs={12} md={6} lg={6}>
              <h2 className="fw-bold mb-4">Key Benefits You Can't Ignore:</h2>
              <ul className={styles["list-unstyled"]}>
                    <li>
                      100% ISO & Industry Compliance Data Quality for AI First
                    </li>
                    <li>Improved Asset Performance & Parts planning</li>
                    <li>Upto 50% Procurement & Sourcing Efficiencies</li>
                    <li>Accelerates & Transforms RISE with SAP ROI</li>
                    <li>Upto 20% Savings on Asset & Service Management</li>
                    <li>Optimized inventory & Work orders completion</li>
                  </ul>
                
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      <section>
        <div>
          <Container>
            <Row className="align-items-center">
              
              <Col xs={12} md={6} lg={6}>
              <h2 className="fw-bold mb-4">SupportedSAPProducts</h2>
              <ul className={styles["list-unstyled"]}>
                    <li>
                    SAPS/4HANACloud,PrivateEdition
                    </li>
                    <li>SAPS/4HANACloud,PublicEdition</li>
                    <li>SAPECC</li>
                    <li>SAPDesign(IPD),SAPPlanning(IBPMRO),</li>
                    <li>SAPBusinessNetwork(BNAC)</li>
                    <li>SAPAssetManagementOperate(APM)</li>
                    
                    <li>SAP Service Management (FSM)</li>
                  </ul>
                
              </Col>
              <Col xs={12} md={6} lg={6}>
                <img loading="lazy"
                  className="w-100"
                  src={require("../../Components/Assets/icontent-foundry/Supported-SAP-Products.png")}
                  alt=""
                  
                />
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      {/* <section className={clsx(styles['supported-sap-products'])}>
        <div>
          <Container>
            <h1 className={styles["extended-header-sap"]}>
              Supported SAP Products
            </h1>
            <Row>
              <Col md={4}>
                <h5>SAP S/4HANA Cloud, Private Edition</h5>
              </Col>
              <Col md={4}>
                <h4>SAP S/4HANA Cloud, Public Editio</h4>
              </Col>
              <Col md={4}>
                <h4>SAP ECC</h4>
              </Col>
            </Row>
          </Container>
        </div>
      </section> */}


      <section className={styles['iso-compliance-section']}>
        <div>
          <Container className="my-5">
            <Row>
            <Col md={6}>
                <img loading="lazy"
                  src={require("../../Components/Assets/icontent-foundry/ISO.png")}
                  alt=""
                  className="w-75"
                />
              </Col>
              <Col md={6}>
              <h2 className="fw-bold mb-4">
                  ISO Compliance
                </h2>
                <ul className={styles["list-unstyled"]}>
                  <li>
                      Industry Best Practices & Standards (ISO 8000, UNSPSC,
                      DUNS, SOX, ISO 14224, ISO 45000,
                  </li>
                  <li>
                      ISO 55000, 11179, IEC 60050 (IEV), ISO 81346, API, TUV3000
                      etc.)
                  </li>
                  <li>
                    Master Data Quality (ISO 8000 100 Series)
                  </li>
                  <li>
                      Asset and Location Hierarchy Management ISO 14224 2016
                  </li>
                  <li>
                   Asset Management ISO 55000 Series
                  </li>
                  <li>
                   ISMS ISO/IEC 27001:2013
                  </li>
                  <li>
                    Quality Management System ISO 9001:2015
                  </li>
                  <li>
                    
                      Open Technical Dictionaries and Data Exchange ISO 22745
                  </li>
                  <li>
                      Industrial Automation Systems & Integration and Data
                      Exchange ISO 29002
                  </li>
                </ul>
              </Col>
              
            </Row>
            <Row className="my-5">
              <Col md={8}></Col>
              <Col></Col>
            </Row>
          </Container>
        </div>
      </section>
      <section>
        <Container>
            <Row>
                <Col md={12}>
                        <img loading="lazy" className="w-100" src={require("../../Components/Assets/icontent-foundry/new-section-img.png")} alt="" />
                </Col>
            </Row>
        </Container>
      </section>
      <section className={styles['pricing-model-section']}>
        <div className="my-2 py-2 ">
          <Container>
            <h1 className={clsx(styles["extended-header-bottom"],"text-center mb-5")}>Pricing Model</h1>
            <Row>
              <Col md={4}>
                <Card className="px-3 py-3">
                  <h3>Subscription-Based</h3>
                  <p>Annual SaaS</p>
                </Card>
              </Col>
              <Col md={4}>
                <Card className="px-3 py-3">
                  <h3>On Premise Licensing</h3>
                  <p>One time license fee, implementation with AMC</p>
                </Card>
              </Col>
              <Col md={4}>
                <Card className="px-3 py-3">
                  <h3>Custom Packages</h3>
                  <p>Tailored solutions for enterprise needs.</p>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    
      <section className={styles["icontentFoundary-hero-section"]}>
        <div >
          <Container className={styles["icontentFoundary-cta"]}>
            
            <h2>One Repository for Standardized, Compliant, and Reliable Data!</h2>
            <p>
            Want to see iContent Foundry in action, get a free data assessment, or talk with a data migration expert?
            </p>
            <Link to="/extended-supply">
                  <Button variant="filled" rightSection={<ArrowOutwardIcon fontSize="20`"/>}>Read more</Button>
                </Link>
           
           
          </Container>
        </div>
      </section>
      
    </>
  );
};

export default IcontenFoundry;
