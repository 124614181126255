import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import "../Pages/AboutPiLog.css"

export const Corporate = () => {
    return (
        <div>
            <section className='Value-propositions-Section1'>
                <Container className=''>
                    <Row style={{ alignItems: 'center' }}>
                        <Col lg={7}>
                            <div className='Strategic-Advisory-Section1-content'>
                                <h1 >Corporate</h1>
                                <p >A value proposition highlights the unique benefits a product or service offers,
                                    explaining why it’s the best choice for addressing customer needs.</p>

                            </div>
                        </Col>
                        <Col lg={5}>
                            <div className="rightBannersection">
                                <img width='75%' src={require("../Components/Assets/AboutPiLog/Corporate_Image.png")} alt='Ai-hero-img' loading='laxzy' />
                            </div>
                        </Col>
                    </Row>
                </Container>

            </section>
            <section>
                <Container className='py-5'>
                    <Row>
                        <Col lg={12}>
                        <div className='empty-div'></div>
                            <div className="about-us">
                            
                                <h2>About PiLog</h2>
                                <p>
                                    Established in 1996, PiLog Group is a global group of independent companies, specializing in Quality Data and Digital Governance and
                                    Analytical solutions supporting multiple data domains in a variety of industries all over the globe. We have many resources operating
                                    globally including Africa's, America's, Europe, Middle East, Asia, etc. The PiLog's solutions are state of the art, focused on creating a
                                    common business language and managing the rules for the creation of high quality, multilingual terminology using Machine Learning,
                                    Artificial Intelligence technologies, and human-augmented algorithms for our customers who are eager to transform their businesses
                                    digitally
                                </p>
                                <p>
                                    PiLog provides exclusive technical dictionaries, industrial libraries with content repositories that are the culmination of research,
                                    development, and execution over the past twenty years embedded into the platform of PiLog Data Quality HUB. All our methodologies,
                                    processes, solutions are compliant with international standards for delivering seamless systematic integration of content into various
                                    platforms such as SAP, S4 HANA, Oracle ERP Cloud, Maximo, MS Dynamics and more.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='fact-sheet'>
                <div className='style-image'>
                <img className='w-50' src={require("../Components/Assets/AboutPiLog/Group 1171277618.png")} alt="FSArtboard" />
                </div>
                    <div className='fact-sheet-heading'>
                    <h2>Fact Sheet</h2>
                    </div>
                    <Row>
                        <Col lg={3}>
                        <div className="fact-sheet-card ">
                            <div>
                            <img className='w-100 rounded-3 shadow-lg' src={require("../Components/Assets/AboutPiLog/FSArtboard 1.jpg")} alt="FSArtboard" />
                            </div>
                            <div>
                                <h5>Award</h5>
                                <p>PiLog Group has been Awarded Best Idea Award from our Global Business customer Vedanta</p>
                            </div>
                        </div>

                        </Col>
                        <Col lg={3}>
                        <div className="fact-sheet-card">
                            <div>
                            <img className='w-100 rounded-3 shadow-lg' src={require("../Components/Assets/AboutPiLog/FSArtboard 2.jpg")} alt="FSArtboard" />
                            </div>
                            <div>
                                <h5>Partners</h5>
                                <p>
                                PiLog is a long standing partner with SAP, having done a large number of projects 
                                </p>
                            </div>
                        </div>
                        </Col>
                        <Col lg={3}>
                        <div className="fact-sheet-card">
                            <div>
                            <img className='w-100 rounded-3 shadow-lg' src={require("../Components/Assets/AboutPiLog/FSArtboard 3.jpg")} alt="FSArtboard" />
                            </div>
                            <div>
                                <h5>PiLog Preferred Records</h5>
                                <p>
                                PiLog has over 10+ million Material, Equipment and Service Master Records
                                </p>
                            </div>
                        </div>
                        </Col>
                        <Col lg={3}>
                        <div className="fact-sheet-card">
                            <div>
                            <img className='w-100 rounded-3 shadow-lg' src={require("../Components/Assets/AboutPiLog/FSArtboard 4.jpg")} alt="FSArtboard" />
                            </div>
                            <div>
                                <h5>PiLog Preferred Hierarchy</h5>
                                <p>
                                Process of collecting spend data from all purchasing sources and systems to impact spending trends and savings.
                                </p>
                            </div>
                        </div>
                        </Col>
                        <Col lg={3}>
                        <div className="fact-sheet-card">
                            <div>
                            <img className='w-100 rounded-3 shadow-lg' src={require("../Components/Assets/AboutPiLog/FSArtboard 5.jpg")} alt="FSArtboard" />
                            </div>
                            <div>
                                <h5>History</h5>
                                <p>
                                Year by year momentum on Strategical and Tactical innovations entitled us as the Digital Transformation Experts in the market
                                </p>
                            </div>
                        </div>
                        </Col>
                        <Col lg={3}>
                        <div className="fact-sheet-card">
                            <div>
                            <img className='w-100 rounded-3 shadow-lg' src={require("../Components/Assets/AboutPiLog/FSArtboard 6.jpg")} alt="FSArtboard" />
                            </div>
                            <div>
                                <h5>Taxonomy/Dictionary</h5>
                                <p>
                                PiLog's taxonomy is covering all the templates of all major industry sectors
                                </p>
                            </div>
                        </div>
                        </Col>
                        <Col lg={3}>
                        <div className="fact-sheet-card">
                            <div>
                            <img className='w-100 rounded-3 shadow-lg' src={require("../Components/Assets/AboutPiLog/FSArtboard 7.jpg")} alt="FSArtboard" />
                            </div>
                            <div>
                                <h5>Customers</h5>
                                <p>
                                PiLog has customers across the globe from almost every industry sector
                                </p>
                            </div>
                        </div>
                        </Col>
                        <Col lg={3}>
                        <div className="fact-sheet-card">
                            <div>
                            <img className='w-100 rounded-3 shadow-lg' src={require("../Components/Assets/AboutPiLog/FSArtboard 8.jpg")} alt="FSArtboard" />
                            </div>
                            <div>
                                <h5>Add-Ons, Certifications and Cloud Ready</h5>
                                <p>
                                SAP certified integration with SAP ECC and S/4 HANA, Co-Innovation Partner
                                </p>
                            </div>
                        </div>
                        </Col>

                    </Row>
               
            </section>
        </div>
    )
}
