import React, { useState,useEffect } from 'react'
import './Header.css'

export const Header = () => {
    const [menuToggle, setMenuToggle] = useState(false);
    const toggleMenu = () => {
      setMenuToggle(!menuToggle);
    };



  return (
    <div>
      <nav id="navbar">
        <div className="nav-wrapper">
          {/* Navbar Logo */}
          <div className="logo">
            {/* Logo Placeholder for Illustration */}
            <a href="#home"><i className="fas fa-chess-knight"></i> Logo</a>
          </div>

          {/* Navbar Links */}
          <ul id="menu" className="desktop-menu">
            <li><a href="#home">Home</a></li>
            <li><a href="#services">Services</a></li>
            <li><a href="#about">About</a></li>
            <li><a href="#contact">Contact</a></li>
            <li>

            <form class="form text-align-right">
      <button>
          <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
              <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" stroke-width="1.333" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
      </button>
      <input class="input" placeholder="Type your text"  type="text"/>
      <button class="reset" type="reset">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
      </button>
  </form>
  </li>
          </ul>
          
        </div>
       
      </nav>

      {/* Menu Icon */}
      <div className={`menuIcon ${menuToggle ? 'toggle' : ''}`} onClick={toggleMenu}>
        <span className="icon icon-bars"></span>
        <span className="icon icon-bars overlay"></span>
      </div>

      {/* Overlay Menu */}
      <div className={`overlay-menu ${menuToggle ? 'open' : ''}`}>
        <ul id="menu">
          <li><a href="#home" onClick={toggleMenu}>Home</a></li>
          <li><a href="#services" onClick={toggleMenu}>Services</a></li>
          <li><a href="#about" onClick={toggleMenu}>About</a></li>
          <li><a href="#contact" onClick={toggleMenu}>Contact</a></li>
        </ul>
        
      </div>
    </div>
  )
}
