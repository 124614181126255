import React from 'react'
import {  Col, Container, Row } from 'react-bootstrap'
import '../Indusries/Industies.css'
import Marquee from 'react-fast-marquee'
import { Carousel } from '@mantine/carousel';
import { useRef } from 'react';
import Autoplay from 'embla-carousel-autoplay';
import { Link } from "react-router-dom";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { Button } from "@mantine/core";
// import bannerImage from ''

export const OilGas = () => {

    const autoplay = useRef(Autoplay({ delay: 2000 }));
    return (
        <div>
            <section className='about-pilog oil-and-gas'>
                <Container>
                    <Row>
                        <Col lg={6} className='about-ilog-left oil-and-gas-left'>

                            <h5 className='rise-with-sap'>Oil and Gas</h5>
                            <h1>Maximize Equipment Uptime, Optimize stock levels of spares</h1>
                            <p>
                                PiLog’s Lean Data Governance for EAM empowers VPs to eliminate downtime, optimize processes, and align asset strategies with production targets delivering 20%+ gains in operational efficiency
                            </p>
                        </Col>
                        <Col lg={6}>
                            <img loading="lazy"className='' style={{ width: '100%' }} src={require("../Assets/industries&company/industries/oil-and-gas-industry-banner-image.png")} alt="" />
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='industry-clients'>
                <div>
                    <h2>Trusted by Industry Pioneers</h2>
                    <p style={{ fontSize: '1.1rem' }}>A legacy of excellence, globally recognized and trusted by top organizations worldwide</p>
                </div>
                <div>
                    <Marquee speed={50} delay={0}>
                        <img loading="lazy"style={{ width: '70%' }} src="https://www.piloggroup.com/img/Client%20Logos/Oil-Gas/ADNOC.png" alt='' />
                        <img loading="lazy"style={{ width: '70%' }} src="https://www.piloggroup.com/img/Client%20Logos/Oil-Gas/BP.png" alt='' />
                        {/* <img loading="lazy"style={{width: '10%'}} src="https://piloggroup.com/img/Client%20Logos/recent-logos/Dubai-airports.png" alt=''/> */}
                        <img loading="lazy"style={{ width: '70%' }} src="https://www.piloggroup.com/img/Client%20Logos/Oil-Gas/Cairn.png" alt='' />
                        <img loading="lazy"style={{ width: '70%' }} src="https://www.piloggroup.com/img/Client%20Logos/Oil-Gas/CCED.png" alt='' />
                        <img loading="lazy"style={{ width: '70%' }} src="https://www.piloggroup.com/img/Client%20Logos/Oil-Gas/KJO-new.jpg" alt='' />
                        <img loading="lazy"style={{ width: '70%' }} src="https://www.piloggroup.com/img/Client%20Logos/Oil-Gas/Noble-Energy.png" alt='' />
                        <img loading="lazy"style={{ width: '70%' }} src="https://piloggroup.com/img/Client%20Logos/recent-logos/Draon-oil.jpg" alt='' />
                        <img loading="lazy"style={{ width: '70%' }} src="https://piloggroup.com/img/Client%20Logos/recent-logos/OQ.jpg" alt='' />
                        <img loading="lazy"style={{ width: '70%' }} src="https://piloggroup.com/img/Client%20Logos/recent-logos/Motiva-logo.jpg" alt='' />
                        <img loading="lazy"style={{ width: '70%' }} src="https://piloggroup.com/img/Client%20Logos/recent-logos/bapco-energies-logo.jpg" alt='' />
                        <img loading="lazy"style={{ width: '70%' }} src="https://www.piloggroup.com/img/Client%20Logos/Chemicals-Refineries/Aramco.png" alt='' />
                        <img loading="lazy"style={{ width: '70%' }} src="https://www.piloggroup.com/img/Client%20Logos/Oil-Gas/Chevron.png" alt='' />
                        {/* <img loading="lazy"style={{ width: '70%' }} src="https://piloggroup.com/img/Client%20Logos/Chemicals-Refineries/HZL.jpg" alt='' /> */}

                    </Marquee>

                    <div className='d-flex justify-content-center align-items-center'>
                        <Link to=''>
                            <button className='partner-btn'>
                                Read More
                            </button>
                        </Link>
                    </div>
                </div>
            </section>

            <section className='industry-leaders-section'>
                <Container>
                    <Row className='justify-content-center align-items-center'>
                        <Col lg={12}>
                            <div class="industry-leaders">
                                <div class="empty-div"></div>
                                <h2>Proven Results for Oil & Gas Operators</h2>

                            </div>
                            <div class=" industry-leaders-achivements">
                                <div class="row d-flex justify-content-center align-items-center">

                                    <div class="col-md-12 the-result">

                                        <div class="row d-flex">
                                            <div class="col-md-6 col-sm-10 col-lg-3 results">
                                                <h3>30% </h3>
                                                <p>improvement in equipment efficiency (ISO 22400 metrics)</p>
                                            </div>
                                            <div class="col-md-6 col-sm-10 col-lg-3 results">
                                                <h3>98%</h3>
                                                <p> spare parts availability for critical repairs</p>
                                            </div>
                                            <div class="col-md-6 col-sm-10 col-lg-3 results">
                                                <h3>25%</h3>
                                                <p>reduction in maintenance backlog</p>
                                            </div>
                                            <div class="col-md-6 col-sm-10 col-lg-3 results">
                                                <h3>12–18</h3>
                                                <p>month ROI (often within 8–10 months)</p>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='data-migration-sap'>
                <Container>
                    <Row className='d-flex justify-content-center align-items-center'>
                        <Col lg={6}>
                            <img loading="lazy"className='w-100' src={require("../Assets/industries&company/industries/Key-Pain-Points-for-Oil-&-Gas-CEOs2.png")} alt="" />
                        </Col>
                        <Col lg={6} className='' style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                            <h2 style={{ fontSize: '2.4rem' }}>Key Data Challenges Impacting Oil and Gas Operations</h2>
                            <p>
                                Let our structured, efficient, and secure migration approach to tackle the biggest data challenges in Oil & Gas. Our end-to-end migration process ensures accuracy, compliance, and zero disruptions, enabling a seamless transition while addressing
                            </p>
                            <div>
                                <p className='my-1'>✦ Escalating Operational Costs</p>
                                <p className='my-1'>✦ Unpredictable Downtime</p>
                                <p className='my-1'>✦ Excess Spares Inventory</p>
                                <p className='my-1'>✦ Regulatory & Compliance Risks</p>
                                <p className='my-1'>✦ Data Silos & Poor Visibility</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='my-5 py-3'>
                <Container>
                    <div>
                        <h2 style={{ fontSize: '2.4rem', textAlign: 'center' }}>How Pilog’s Solves These Challenges</h2>
                    </div>
                    <Row className="d-flex justify-content-center align-items-center">
                        <Col lg={4} md={6} sm={10}>
                            <div className='sap-roadmap'>
                                <img loading="lazy"className='' src={require('../Assets/SAP/Pre-Migration Assessment_Icon-01.png')} alt="" />
                                <h4>Predictive Maintenance</h4>
                                <p>Reduce downtime by 30%+ with AI-driven asset health monitoring. </p>
                            </div>

                        </Col>
                        <Col lg={4} md={6} sm={10}>
                            <div className='sap-roadmap'>
                                <img loading="lazy"className='' src={require('../Assets/SAP/Data Cleansing & Preparation_Icon-01.png')} alt="" />

                                <h4>Inventory Optimization:</h4>
                                <p>
                                    Slash spare parts costs by up to 20% using demand forecasting and real-time tracking.
                                </p>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={10}>

                            <div className='sap-roadmap'>
                                <img loading="lazy"className='' src={require('../Assets/SAP/Customized Migration Plans_Icon-01.png')} alt="" />

                                <h4>Unified Data Ecosystem:</h4>
                                <p>
                                    Break down silos with a single source of truth for operations, procurement, and compliance.
                                </p>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={10}>
                            <div className='sap-roadmap'>
                                <img loading="lazy"className='' src={require('../Assets/SAP/Real-Time Monitoring Icon-01.png')} alt="" />

                                <h4>Automated Compliance:</h4>
                                <p>
                                    Stay audit-ready with automated reporting for OSHA, ISO, and ESG standards.
                                </p>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={10}>
                            <div className='sap-roadmap'>
                                <img loading="lazy"className='' src={require('../Assets/SAP/Training & Empowerment_Icon-01.png')} alt="" />

                                <h4>Seamless ERP Integration:</h4>
                                <p>
                                    Sync with SAP S4 HANA, Oracle, or IBM Maximo for real-time data flows.
                                </p>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={10}>
                            <div className='sap-roadmap'>
                                <img loading="lazy"className='' src={require('../Assets/SAP/Post-Migration Optimization_Icon-01.png')} alt="" />

                                <h4>Global Scalability:</h4>
                                <p>
                                    Manage 100+ sites with localized compliance rules and centralized governance.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="client-revies-on-industries">
                <Container>
                    <div className="row text-center test-leader-content">
                        <div className="empty-div"></div>
                        <h2>Trusted by Asset-Intensive Operators</h2>

                    </div>
                    <Carousel withIndicators height={300} dragFree loop slideGap="md" align="start"
                        plugins={[autoplay.current]}
                        onMouseEnter={autoplay.current.stop}
                        onMouseLeave={autoplay.current.reset}
                    >
                        <Carousel.Slide className='carousel-inner'>
                            <div className="carousel-item">
                                <div className="quote-icon">❝</div>
                                <div className="stars">★★★★★</div>
                                <p className="quote">With accurate data and complete audit trails, PiLog LDG made regulatory
                                    compliance effortless, minimizing risks and improving reporting transparency!</p>
                                <p className="author">— Gartner®, Hype Cycle for Supply Chain Strategy</p>
                            </div>
                        </Carousel.Slide>
                        <Carousel.Slide className='carousel-inner'>
                        <div className="carousel-item">
                                <div className="quote-icon">❝</div>
                                <div className="stars">★★★★★</div>
                                <p className="quote">We reduced inventory costs by 15% and achieved 100% compliance
                                with PiLog’s scalable framework.</p>
                                <p className="author">— VP of Digital Transformation, Leading Indian Energy Conglomerate</p>
                            </div>
                        </Carousel.Slide>
                        <Carousel.Slide className='carousel-inner'>
                        <div className="carousel-item">
                                <div className="quote-icon">❝</div>
                                <div className="stars">★★★★★</div>
                                <p className="quote">Standardizing 28K+ materials with PiLog’s MDRM system cut
                                procurement costs by 20%.</p>
                                <p className="author">— Head of Procurement, Global Refinery Operator</p>
                            </div>
                        </Carousel.Slide>
                        {/* ...other slides */}
                    </Carousel>

                </Container>
            </section>


            <section className='sap-cta'>
                    <div >
                      <Container className='sap-cta-content'>
                        
                        <h2>Ready to Transform Assets into Competitive Advantage?</h2>
                        <p>
                        Want to see iContent Foundry in action, get a free data assessment, or talk with a data migration expert?
                        </p>
                        <Link to="/extended-supply">
                              <Button variant="filled" rightSection={<ArrowOutwardIcon fontSize="20`"/>}>Contact US Today </Button>
                            </Link>
                       
                       
                      </Container>
                    </div>
                  </section>


        </div>
    )
}
