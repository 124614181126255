import { useState } from "react";
import { Container, Row, Col,} from "react-bootstrap";
import styles from "./extendedSupply.module.css";
import clsx from "clsx";

const ExtendedSupply = () => {
  const [activeIndex, setActiveIndex] = useState(0); // Store active row index

  const handleAccordians = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  return (
    <>
      <section className={styles["extendedSupply-hero-section"]}>
        <div className={styles["extendedSupply-inner"]}>
          <Container>
            <h1>Extended Supply Chain Optimization</h1>
            <p>
              With ~ <b>70</b> to <b>75%</b> average organizational costs
              associated with materials and services sourcing, procurement and
              inventory
            </p>
          </Container>
        </div>
      </section>
      <section className={"py-5 my-5"}>
        <div>
          <Container>
            <Row className="align-items-center">
              <Col md={4}>
                <img
                  className="w-100"
                  src={require("../../Components/Assets/extended-supply/Extended-Supply-Chain-Optimization-Img1.png")}
                  alt=""
                />
              </Col>
              <Col md={8}>
                <p>
                  Increasing costs and risks to right sourcing, optimal
                  procurement, and inventory with most of the industries spend
                  investment at whopping 70-75% of annual budgets. Continual
                  cost reduction drives in highly violate supply chain will be
                  critical success factor
                </p>
                <p className="my-3 fs-4">
                  <b>“ A DOLLAR SAVED IS A DOLLAR EARNED ”</b>
                </p>
                <p className="my-3 fs-5">
                  <b>CxO’s</b> and <b>CFO’s</b> always self-reflect
                  opportunities...
                  <br /> WHERE and HOW!!!
                </p>
                <ul>
                  <li>
                    <p>Spend performance visibility</p>
                  </li>
                  <li>
                    <p>Real Time Inventory hoarding information</p>
                  </li>
                  <li>
                    <p>Confidence in partnership</p>
                  </li>
                  <li>
                    <p>Achieve industry benchmarks KPI s and KRAs</p>
                  </li>
                  <li>
                    <p>Lower Total Cost of Ownership.</p>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <section>
        <div>
          <Container>
            <Row>
              <Col xs={12} md={6} lg={6}>
                <h1 className={styles["extended-header-common"]}>
                  Optimization of Category, Sourcing, Procurement and Contract
                  Management
                </h1>
                <ul className={styles["optimization-list"]}>
                  <li>
                    <p>
                      Qualified Master Data for vendors, customers, materials,
                      and services
                    </p>
                  </li>
                  <li>
                    <p>Spend, Inventory and Partner Performance Visibility</p>
                  </li>
                  <li>
                    <p>
                      SOPs and Strategy Inventory, Sourcing and Business Partner
                    </p>
                  </li>
                  <li>
                    <p>
                      Sourcing Projects and Contracts enabling quality,
                      quantity, value
                    </p>
                  </li>
                  <li>
                    <p>Inventory Optimization and Automation</p>
                  </li>
                  <li>
                    <p>
                      Business Partner Life Cycle Management covering 360-degree
                      alignment in collaboration and performance management
                    </p>
                  </li>
                  <li>
                    <p>Continual Monitoring, Reporting, and Control</p>
                  </li>
                </ul>
              </Col>
              <Col xs={12} md={6} lg={6}>
                <img
                  className="w-100"
                  src={require("../../Components/Assets/extended-supply/Extended_Supply_Chain_Optimization_Section_2_Image-01.png")}
                  alt=""
                  loading="lazy"
                />
              </Col>
            </Row>
            <Row className="my-5">
              <Col md={6}>
                <div className={styles["extended-images-grid"]}>
                  <div className={styles["extended-img-grid-childs"]}>
                    <img
                      src={require("../../Components/Assets/extended-supply/Group 1171277621.png")}
                      alt=""
                      loading="lazy"
                    />
                    <p>Spend Cube</p>
                  </div>
                  <div className={styles["extended-img-grid-childs"]}>
                    <img
                      src={require("../../Components/Assets/extended-supply/Group 1171277622.png")}
                      alt=""
                      loading="lazy"
                    />
                    <p>Pioneer Applications</p>
                  </div>
                  <div className={styles["extended-img-grid-childs"]}>
                    <img
                      src={require("../../Components/Assets/extended-supply/Group 1171277623.png")}
                      alt=""
                      loading="lazy"
                    />
                    <p>Critical Success Factors</p>
                  </div>
                  <div className={styles["extended-img-grid-childs"]}>
                    <img
                      src={require("../../Components/Assets/extended-supply/Group 1171277624.png")}
                      alt=""
                      loading="lazy"
                    />
                    <p>Cost Metrics</p>
                  </div>
                  <div className={styles["extended-img-grid-childs"]}>
                    <img
                      src={require("../../Components/Assets/extended-supply/Group 1171277625.png")}
                      alt=""
                      loading="lazy"
                    />
                    <p>Evaluation</p>
                  </div>
                  <div className={styles["extended-img-grid-childs"]}>
                    <img
                      src={require("../../Components/Assets/extended-supply/Group 1171277626.png")}
                      alt=""
                      loading="lazy"
                    />
                    <p>Category Management</p>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <ul className={styles["optimization-list"]}>
                  <li>
                    <p>Categorize commodities and services</p>
                  </li>
                  <li>
                    <p>Real-time Spend Management using machine learning</p>
                  </li>
                  <li>
                    <p>Predict Demand and Future Sourcing using AI</p>
                  </li>
                  <li>
                    <p>Optimization of Sourcing Projects execution</p>
                  </li>
                  <li>
                    <p>
                      Implementation of full proof and controlled Bid Evaluation
                      systems
                    </p>
                  </li>
                  <li>
                    <p>Implement Smart Contracts</p>
                  </li>
                  <li>
                    <p>Automation of repetitive procurement processes</p>
                  </li>
                  <li>
                    <p>Secure Digital Payments</p>
                  </li>
                  <li>
                    <p>Enhance Internal Audits and Controls</p>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <section className={"py-5 my-5"}>
        <div>
          <Container>
            <h1 className={styles["extended-header-common"]}>
              PiLog s low investment value analysis will enable Quick Wins to
              Business Transformations and Digitalization
            </h1>
            <Row className="mt-5">
              <Col>
                {[
                  {
                    title: "Materials Criticality",
                    img: "Group 1171277623 (1).png",
                    content:
                      "Material Criticality = f (ABC Spend YoY, FMSN Pattern on consumption YoY, XYZ Inventory Hoarding, HML High Medium Low, VED Criticality and Impact etc.)",
                  },
                  {
                    title: "Data Health Assessment",
                    img: "Group 1171277622 (1).png",
                    content:
                      "Qualitative data management against standards and provisional reporting Assessment on quality dimensions - Completeness, Accuracy, Traceability, Consistency, Timeliness, Availability, and Validity",
                  },
                  {
                    title: "Process Maturity Model",
                    img: "Group 1171277621 (1).png",
                    content:
                      "Supply Chain (S2P) Maturity = f (cost of procurement; contract compliance; effective supplier management; standards compliance to safety, security, quality; operational efficiencies, etc.)The weights and factoring of parameters depends upon the organization, its industry segment, and the S2P category",
                  },
                ].map((item, index) => (
                  <Row
                    key={index}
                    className={clsx("my-4 align-items-center", {
                      [styles.activeRow]: activeIndex === index,
                    })}
                    onClick={() => handleAccordians(index)}
                  >
                    <Col md={2} className="align-self-start">
                      <img
                        className="w-100"
                        src={require(`../../Components/Assets/extended-supply/${item.img}`)}
                        alt=""
                      />
                    </Col>
                    <Col md={10}>
                      <h6 className="fw-bolder">{item.title}</h6>
                      {activeIndex === index && (
                        <div
                          className={clsx(styles.accordionContent, {
                            [styles.accordionOpen]: activeIndex === index,
                          })}
                        >
                          <p>{item.content}</p>
                        </div>
                      )}
                    </Col>
                  </Row>
                ))}
              </Col>
              <Col>
                <img
                  className="w-100"
                  src={require("../../Components/Assets/extended-supply/1708634310245.png")}
                  alt=""
                />
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <section>
        <div>
          <Container>
            <Row>
              <Col md={6}>
                <h1 className={styles["extended-header-common"]}>
                  Key Result Area(s) and Performance Indicator(s)
                </h1>
                <p className="fw-bold my-4 fs-4">Business Outcomes</p>
                <ul className={styles["keyresults-list"]}>
                  <li>
                    <p>100% Contract Compliance</p>
                  </li>
                  <li>
                    <p>>95% On time Delivery</p>
                  </li>
                  <li>
                    <p>100% Delivery Quality Compliance</p>
                  </li>
                  <li>
                    <p>>98% Order Confirmation</p>
                  </li>
                  <li>
                    <p>>95% Quality meeting PO expectations</p>
                  </li>
                  <li>
                    <p>100% Compliance to Regulatory, Safety, and Standards</p>
                  </li>
                  <li>
                    <p>
                      Reduce the pricing YoY by 15% A, B, C (Service
                      Criticality)
                    </p>
                  </li>
                  <li>
                    <p>Reduce invoicing errors to less than 5%</p>
                  </li>
                </ul>
              </Col>
              <Col md={6}>
                        <img src={require("../../Components/Assets/extended-supply/Organizations-hunger-to-save-money_Inforgraphic-02.webp")} alt="" />
              </Col>
            </Row>
            <Row className="my-5">
              <Col md={12}>
                <table class="table table-striped   ">
                  <thead>
                    <tr>
                      <th></th>
                      <th
                        style={{
                          backgroundColor: "#0b4a99",
                          color: "white",
                          fontWeight: "400",
                        }}
                      >
                        Key Challenges
                      </th>
                      <th
                        style={{
                          backgroundColor: "#0b4a99",
                          color: "white",
                          fontWeight: "400",
                        }}
                      >
                        Key Opportunities
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Source to Pay</td>
                      <td>
                        Availability of right material or service at the right
                        quality, quantity, price, time Adherence to contract
                        compliance
                      </td>
                      <td>
                        Ways to improve spend visibility and re-categorization
                        Demand amalgamations and sourcing optimization
                      </td>
                    </tr>
                    <tr>
                      <td>Plan to Inventory</td>
                      <td>
                        Having an optimum level of inventory for fulfilling a
                        demand Effective and effective management warehouse
                        operations and costs
                      </td>
                      <td>
                        Optimization of parameters such as SS, ROP, EOQ, Min. /
                        Max. levels etc. Automation and Innovation with JIT
                        delivery, distributed supply chain
                      </td>
                    </tr>
                    <tr>
                      <td>Business Partner Collaboration</td>
                      <td>
                        Having the right partners to support and accelerate
                        growth in a bad time Ability to think out of the box
                        with innovative products and services
                      </td>
                      <td>
                        Improved process of registrations, evaluations,
                        continual monitoring, performance assessment, and end to
                        end life cycle management
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col md={6}>
                <p>
                  As most of the businesses are controlled by Master and Meta
                  Management and thought leaders effective decision with
                  Augmented Predictive Analytics, PiLog Systematic and
                  Innovative Approach help organizations with Kaizen to
                  Transformation !!!
                </p>
                <img
                  className="w-100"
                  src={require("../../Components/Assets/extended-supply/Make_the-Connection-to-the_Business-01-01.png")}
                  alt=""
                />
              </Col>
              <Col md={6}>
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th
                        style={{
                          backgroundColor: "#0b4a99",
                          color: "white",
                          fontWeight: "400",
                        }}
                      >
                        Key Result Area
                      </th>
                      <th
                        style={{
                          backgroundColor: "#0b4a99",
                          color: "white",
                          fontWeight: "400",
                        }}
                      >
                        Key Performance Indicator
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Contract Management</td>
                      <td>100% Contract Compliance</td>
                    </tr>
                    <tr>
                      <td>JIT / ON Time Delivery</td>
                      <td>
                        95% On time Delivery 100% Delivery Quality Compliance
                      </td>
                    </tr>
                    <tr>
                      <td>Order Collaboration</td>
                      <td>>98% Order Confirmation</td>
                    </tr>
                    <tr>
                      <td>Goods and Services Quality</td>
                      <td>>95% Quality meeting PO expectations</td>
                    </tr>
                    <tr>
                      <td>Design Collaboration</td>
                      <td>
                        100% Compliance to Regulatory, Safety, and Standards
                      </td>
                    </tr>
                    <tr>
                      <td>Sourcing Collaboration</td>
                      <td>
                        Reduce the pricing YoY by 15% A, B, C (Service
                        Criticality)
                      </td>
                    </tr>
                    <tr>
                      <td>Invoice Management</td>
                      <td>Reduce invoicing errors to less than 5%</td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <section>
        <div className="my-5 py-5 text-center">
          <Container>
            <h1 className={styles["extended-header-bottom"]}>
              Client Value Management and Success Stories
            </h1>
            <p>
              A Petrochemical Major in KSA has invited PiLog and multiple other
              consulting leaders to identify the challenges and define problem
              statements in their Supply Chain Sourcing, Procurement and
              Inventory factors as their inventory was piling up with YoY
              increase in inventory hoarding, sourcing, procurement and
              transportation costs and increased risk of supply chain sustenance
            </p>
            <button
              className={clsx(styles["download-story-btn"], "w-auto mt-5")}
            >
              Download Success Story
            </button>
          </Container>
        </div>
      </section>
    </>
  );
};

export default ExtendedSupply;
