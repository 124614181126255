import React, { useState, useEffect } from 'react'
import { Carousel, Col, Container, Row } from 'react-bootstrap'

import './AiLens.css'

import { ReadMoreButton } from '../ExtraComponents/ReadMoreButton'
import { CarousalComponent } from '../CarousalComponent/CarousalComponent'
import { ValidForm } from '../ContactForm/ValidForm'



export const AiLens = () => {
    return (
        <div>
            <section className='Ai-Lens-top'>
                <Container className=''>
                    <Row style={{ alignItems: 'center' }}>
                        <Col lg={7}>
                            <div className='AiLens-heading'>
                                <h1 >AI Lens<img style={{ width: "5%" }} src={require("../Assets/AiLens/AI-Lens-Icon-01.png")} alt="Ai Lens Icon" loading="lazy" />
                                    , A Fastest & Most-intelligent <br /> Virtual Assistant Made For You</h1>
                                <p >It is for everyone and everything. Revamp your Data Management
                                    and Governance Startegies with PiLog’s AI Assistant Embedded
                                    in the iDxP Suite.</p>

                            </div>
                        </Col>
                        <Col lg={5}>
                            <div className="rightBannersection">
                                <img width='100%' src={require("../Assets/AiLens/Generative & Augmented AI.jpg")} alt='Ai-hero-img' loading='lazy' />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

           

            <section className='Ai-Lens-section2'>
                <Container>
                    <Row>
                        <Col lg={8}>
                            <div className='empty-div'></div>
                            <div className='Pilogs-copilot'>
                                <h2 className='text-start'>AI Lens is PiLog’s Copilot, a Virtual Assistant<br />
                                    Embedded in iDxP Product Suite​</h2>
                                <p className='text-start'>
                                    The Conversational AI is application of Artificial Intelligence that simulates human like conversations engaged in
                                    answering queries, guides the users as subject matter expert, provides insights on specific data points, showcases
                                    the analytics, pivoting the data,transverses the processes as per the personalized configuration
                                </p>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className='key-aspect-section mt-4'>
                                <h3>AI Lens is Conceived & Designed</h3>

                                <Row className='my-1 p-1 '>
                                    <Col lg={6} className='my-2'>
                                        <div className='d-flex gap-2 '>
                                            <img className='Ai-lens-conceived' src={require("../Assets/AiLens/AILens_Icons/Conversational AI​_Icon.png")} alt="ldg-key-aspects" loading="lazy" />
                                            <p style={{ fontSize: '14px' }}>Conversational AI​</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className='my-2'>
                                        <div className='d-flex gap-2  '>
                                            <img className='Ai-lens-conceived' src={require("../Assets/AiLens/AILens_Icons/Scaled down LLM Model_Icon.png")} alt="ldg-key-aspects" loading="lazy" />
                                            <p style={{ fontSize: '14px' }}>Scaled down LLM Model</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className='my-2'>
                                        <div className='d-flex gap-2 '>
                                            <img className='Ai-lens-conceived' src={require("../Assets/AiLens/AILens_Icons/Generative AI Approach_Icon.png")} alt="ldg-key-aspects" loading="lazy" />
                                            <p style={{ fontSize: '14px' }}>Generative AI Approach</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className='my-2'>
                                        <div className='d-flex gap-2 '>
                                            <img className='Ai-lens-conceived' src={require("../Assets/AiLens/AILens_Icons/Trained Contextually_Icon.png")} alt="ldg-key-aspects" loading="lazy" />
                                            <p style={{ fontSize: '14px' }}>Trained Contextually</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className='my-2'>
                                        <div className='d-flex gap-2 '>
                                            <img className='Ai-lens-conceived' src={require("../Assets/AiLens/AILens_Icons/N​​LP Model​_icon.png")} alt="ldg-key-aspects" loading="lazy" />
                                            <p style={{ fontSize: '14px' }}>N​​LP Model​</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className='my-2'>
                                        <div className='d-flex gap-2 '>
                                            <img className='Ai-lens-conceived' src={require("../Assets/AiLens/AILens_Icons/Knowledge Base_Icon.png")} alt="ldg-key-aspects" loading="lazy" />
                                            <p style={{ fontSize: '14px' }}>Knowledge Base</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className='my-2'>
                                        <div className='d-flex gap-2'>
                                            <img className='Ai-lens-conceived' src={require("../Assets/AiLens/AILens_Icons/Augmented AI_icon.png")} alt="ldg-key-aspects" loading="lazy" />
                                            <p style={{ fontSize: '14px' }}>Augmented AI</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className='my-2'>
                                        <div className='d-flex gap-2 '>
                                            <img className='Ai-lens-conceived' src={require("../Assets/AiLens/AILens_Icons/Configurable Features​​_Icon.png")} alt="ldg-key-aspects" loading="lazy" />
                                            <p style={{ fontSize: '14px' }}>Configurable Features​​</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className='my-2'>
                                        <div className='d-flex gap-2 align-items-center text-start'>
                                            <img className='Ai-lens-conceived' src={require("../Assets/AiLens//AILens_Icons/Simple & Extended Analytics​_Icon.png")} alt="ldg-key-aspects" loading="lazy" />
                                            <p style={{ fontSize: '14px' }}>Simple & Extended
                                                Analytics​</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className='my-2'>
                                        <div className='d-flex gap-2 align-items-center text-start'>
                                            <img className='Ai-lens-conceived' src={require("../Assets/AiLens/AILens_Icons/Real time & Staged Integration with SAP or Oracle or APIs​_Icon.png")} alt="ldg-key-aspects" loading="lazy" />
                                            <p style={{ fontSize: '14px' }}>Real time & Staged Integration with SAP or Oracle or APIs​</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className='my-2'>
                                        <div className='d-flex gap-2 align-items-center text-start'>
                                            <img className='Ai-lens-conceived' src={require("../Assets/AiLens/AILens_Icons/Guide as Subject Matter Expert​_Icon.png")} alt="ldg-key-aspects" loading="lazy" />
                                            <p style={{ fontSize: '14px' }}>Guide as Subject Matter
                                                Expert​</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className='my-2'>
                                        <div className='d-flex gap-2 align-items-center text-start'>
                                            <img className='Ai-lens-conceived' src={require("../Assets/AiLens/AILens_Icons/Qualitative Validations & more​​_Icon.png")} alt="ldg-key-aspects" loading="lazy" />
                                            <p style={{ fontSize: '14px' }}>Qualitative Validations & more​​</p>
                                        </div>
                                    </Col>

                                </Row>



                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className='key-aspect-images'>
                                <img className='image-1' src={require("../Assets/AiLens/AI-Img.png")} alt="ldg-key-aspects" loading="lazy" />
                                {/* <img className='image-gif' src={require("../Assets/LDG/cloud_icon.gif")} alt="ldg-key-aspects-cloud" /> */}

                            </div>

                        </Col>
                    </Row>
                </Container>

            </section>

            <section className='explore-ailens'>
                <Container>
                    <Row className='mt-4'>
                        <Col lg={5}>

                            <div>
                                <img className='w-100' src={require("../Assets/AiLens/Group.png")} alt="group" loading="lazy" />
                            </div>
                        </Col>
                        <Col lg={7}>
                        <div className='empty-div'></div>
                            <div className='explore-ailens-heading'>
                                <h2>Explore What’s Possible<br/>With AI Lens</h2>
                            </div>
                            <div className='border shadow-sm  rounded p-2 my-2 text-start'>
                                <h6>Enhanced Data Governance</h6>
                                <Row>
                                    <Col>
                                        <div>
                                            <ul className='explore-topics'>
                                                <li>Automated compliance checks</li>
                                                <li>Enforcing data quality standards</li>
                                            </ul>

                                        </div>
                                    </Col>
                                    <Col>
                                        <div className='explore-topics'>
                                            <ul><li>Maintain comprehensive audit trails</li></ul>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className='border shadow-sm rounded p-2 my-2 text-start'>
                                <h6>Improved Operational Efficiency</h6>
                                <Row>
                                    <Col>
                                        <div>
                                            <ul className='explore-topics'>
                                                <li>Streamlined processes</li>
                                                <li>Reduced manual intervention</li>
                                            </ul>

                                        </div>
                                    </Col>
                                    <Col>
                                        <div>
                                            <ul className='explore-topics'>
                                                <li>Accelerated time-to-insight</li>
                                                <li>Strategic resource allocation</li>
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <div className='border shadow-sm rounded p-1 my-2 text-start'>
                                <h6>Advanced Predictive Analytics</h6>
                                <Row>
                                    <Col>
                                        <div>
                                            <ul className='explore-topics'>
                                                <li>Anticipate market trends</li>
                                                <li>Forecast business outcomes</li>
                                            </ul>

                                        </div>
                                    </Col>
                                    <Col>
                                        <div>
                                            <ul className='explore-topics'>
                                                <li>Identify growth opportunities</li>
                                                <li>Informed decision-making</li>
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className='border shadow-sm rounded p-2 my-2 text-start'>
                                <h6>Drive Revenue & Growth</h6>
                                <Row>
                                    <Col>
                                        <div>
                                            <ul className='explore-topics'>
                                                <li>Transparency & accountability</li>
                                                <li>Competitive advantage</li>
                                            </ul>

                                        </div>
                                    </Col>
                                    <Col>
                                        <div>
                                            <ul className='explore-topics'>
                                                <li>Risk Mitigation</li>
                                                <li>Actionable insights</li>
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className='border shadow-sm rounded p-2 my-2 text-start'>
                                <h6>Scalability & Adaptability</h6>
                                <Row>
                                    <Col>
                                        <div>
                                            <ul className='explore-topics'>
                                                <li>Expanding data volumes & diverse data sources</li>
                                                <li>Scaling operations globally</li>
                                            </ul>

                                        </div>
                                    </Col>
                                    <Col>
                                        <div>
                                            <ul className='explore-topics'>
                                                <li>Adaption to regulatory changes</li>
                                                <li>Agility & resilience in data management strategies</li>
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>

            <section style={{ background: '#fff' }}>

                {/* <Container className='usecase-redefine'>
                    <h2 className=''>Use Cases-Redefine How You Interact With Data</h2>
                    <CarousalComponent />

                </Container> */}
                <Container>
                    <h2 className='usecase-redefine'>Use Cases- <span className='unboldtext'>Redefine How You Interact With Data</span></h2>

                    <Row>
                        <Col lg={12}>
                            <Carousel data-bs-theme="dark">
                                <Carousel.Item >
                                    <img className='w-100' src={require("../Assets/AiLens/Use-Case-1.png")} alt='Use-Case-1' loading="lazy" />


                                </Carousel.Item>
                                <Carousel.Item>
                                    <img className='w-100' src={require("../Assets/AiLens/Use-Case-2.png")} alt='Use-Case-2' loading="lazy" />

                                </Carousel.Item>
                                <Carousel.Item >
                                    <img className='w-100' src={require("../Assets/AiLens/Use-Case-3.png")} alt='Use-Case-3' loading="lazy" />

                                </Carousel.Item>
                                <Carousel.Item>
                                    <img className='w-100' src={require("../Assets/AiLens/Use-Case-4.png")} alt='Use-Case-4' loading="lazy" />

                                </Carousel.Item>
                                <Carousel.Item>
                                    <img className='w-100' src={require("../Assets/AiLens/Use-Case-5.png")} alt='Use-Case-5' loading="lazy" />

                                </Carousel.Item>
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='py-5 mt-4 justify-content-center align-items-center ai-lens-form-section'>
                <Container>
                    <Row className='justify-content-center align-items-center'>
                        <Col lg={5}>
                            <div className='ai-lens-cta'>
                                <h2>Want to Accelerate Your Data Governance Success with AI Lens?</h2>
                                <p>
                                    The conversational interface facilitates secure and compliant
                                    data access that streamlines internal processes. Make the best
                                    use of AI-driven search capabilities for quick and accurate
                                    data discovery across your databases and repositories while
                                    reducing search times. Analyze historical data patterns
                                    through predictive analytics to make actionable decisions.
                                </p>
                            </div>
                        </Col>
                        <Col lg={7}>
                            <div className='shadow rounded p-3'>
                                <ValidForm />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

        </div>
    )
}


