import React, { useEffect, useState } from 'react'
import { Card, Col, Container,  Row,} from 'react-bootstrap'
import './Sap.css'

import { Link } from "react-router-dom";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { Button } from "@mantine/core";

export const RaiseWithSAP = () => {

    const [activeIndex, setActiveIndex] = useState(0);

    const contentData = [
        {
            heading: "Seamless Migration ",
            description:
                "Move from legacy systems to SAP S/4HANA with confidence whether it’s brownfield, greenfield, or hybrid, we ensure a smooth, secure transition. ",

        },
        {
            heading: "Built-in Compliance & Best Practices ",
            description:
                "Align with SAP-recommended methodologies effortlessly. Our automated data validation, transformation, and enrichment ensure flawless compliance with SAP S/4HANA standards. ",

        },
        {
            heading: "Faster Migration, Minimal Downtime ",
            description:
                "Let our AI-driven automation and parallel processing slash cutover time while real-time data reconciliation ensures 100% accuracy post-migration. ",

        },
        {
            heading: "Full Visibility & Risk-Free Execution ",
            description:
                "Stay in control with a centralized dashboard tracking every step of your migration. Our built-in risk assessment and data quality checks prevent errors before go-live. ",
        },
        {
            heading: "Cost-Efficient & Scalable for Growth ",
            description:
                "Reduce TCO by eliminating redundant data, optimize performance, and scale effortlessly as your business evolves with SAP S/4HANA.  ",
        },
    ];

    // Auto rotate content every 5 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % contentData.length);
        }, 7000);
        return () => clearInterval(interval);
    }, [contentData.length]);

    return (
        <div>
            <section className='about-pilog'>
                <Container>
                    <Row>
                        <Col lg={7} className='about-ilog-left'>

                            <h5 className='rise-with-sap'>RISE with SAP</h5>
                            <h1>A New Avenue to Intelligent Enterprise Transformation</h1>
                            <p>
                                Modernize legacy systems, unlock the power of your data, and drive continuous innovation by seamlessly migrating to SAP S/4HANA Cloud to stay ahead of the competition.
                            </p>
                        </Col>
                        <Col lg={5}>
                            <img loading="lazy" className='' style={{ width: '600px' }} src={require("../Assets/SAP/RISE-with-SAP.png")} alt="" />
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='rise-with-sap-description'>
                <Container>
                    <Row className='d-flex justify-content-center align-items-center'>
                        <Col lg={6}>
                            <img loading="lazy" className='w-100' src={require("../Assets/SAP/RISE-with-SAP3.png")} alt="" />

                        </Col>
                        <Col lg={6} className='' style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                            <h2 style={{ fontSize: '2.4rem' }}>Powered by Data Science. Augmented by AI. Built for People.</h2>
                            <p>
                                Unlock the full potential of your data with an end-to-end solution that extracts, integrates, cleans, and visualizes data for seamless migration. Transition effortlessly to SAP S/4HANA Cloud while ensuring data quality.
                            </p>
                            <div>
                              
                                <p className='my-1'> Establish Data Quality for “RISE with SAP”</p>
                                <p className='my-1'> Consolidate Data and Build Analytics</p>
                                <p className='my-1'> Build Data Orchestration</p>
                              

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='data-migration-sap'>
                <Container>
                    <Row className='d-flex justify-content-center align-items-center'>

                        <Col lg={6} className='' style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                            <h2 style={{ fontSize: '2.4rem' }}>Your Data Migration, Redefined! </h2>
                            <p>
                                Move your data with confidence using a structured, efficient, and secure approach. Our end-to-end migration process ensures accuracy, compliance, and zero disruptions, enabling a seamless transition to your new system.
                            </p>
                            <div>
                            
                                <p className='my-1'> Data Extraction</p>
                                <p className='my-1'> Data Validation</p>
                                <p className='my-1'> Data Transformation</p>
                                <p className='my-1'> Data Consolidation & Merging</p>
                                <p className='my-1'> Data Loading</p>
                                <p className='my-1'> Reconciliation</p>
                              
                            </div>
                        </Col>
                        <Col lg={6}>
                            <img loading="lazy" className='w-100' src={require("../Assets/SAP/Data-Migration-SAP3.png")} alt="" />

                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='roadmap '>
                <Container>
                    <div>
                        <h2 style={{ fontSize: '2.4rem', textAlign:'center' }}>PiLog’s Value Proposition for SAP S/4HANA Migration </h2>
                    </div>
                    <Row className='d-flex justify-content-center align-items-center'>
                        <Col lg={6}>
                        <img loading="lazy" className='w-100' src={require("../Assets/SAP/Supported-SAP-Products.png")} alt="" />

                        </Col>
                        <Col lg={6}>
                            {contentData.map((item, index) => (
                                <Card
                                    key={index}
                                    className={`mb-3 shadow-sm border-0 ${index === activeIndex ? "bg-light" : ""
                                        }`}
                                    onClick={() => setActiveIndex(index)} // Optional manual open on click
                                    style={{ cursor: "pointer" }}
                                >
                                    <Card.Body>
                                        <Card.Title className="sap-values-h2">{item.heading}</Card.Title>
                                        {index === activeIndex && (
                                            <>
                                                <Card.Text>{item.description}</Card.Text>

                                            </>
                                        )}
                                    </Card.Body>
                                </Card>
                            ))}
                        </Col>
                    </Row>
                </Container>

            </section>

            <section className='my-5 py-3'>
                <Container>
                <div>
                        <h2 style={{ fontSize: '2.4rem', textAlign:'center' }}>Our Roadmap to A Seamless & Flawless SAP S/4HANA Transition</h2>
                    </div>
                    <Row className="d-flex justify-content-center align-items-center">
                        <Col lg={4} md={6} sm={10}>
                        <div className='sap-roadmap'>
                            <img loading="lazy" className='' src="https://piloggroup.com/industries/img/dynamic/Inventory%20Optimization_Icon-01.png" alt="" />
                            <h4>Pre-Migration Assessment</h4>
                            <p>We analyze systems, data, and processes to identify risks and create a clear migration roadmap. </p>
                        </div>

                        </Col>
                        <Col lg={4} md={6} sm={10}>
                        <div className='sap-roadmap'>
                        <img loading="lazy" className='' src="https://piloggroup.com/industries/img/dynamic/Inventory%20Optimization_Icon-01.png" alt="" />

                            <h4>Data Cleansing & Preparation</h4>
                            <p>
                            AI-driven profiling, deduplication, and enrichment ensure accurate, standardized, and SAP-compliant data. 
                            </p>
                        </div>
                        </Col>
                        <Col lg={4} md={6} sm={10}>
                        
                        <div className='sap-roadmap'>
                        <img loading="lazy" className='' src="https://piloggroup.com/industries/img/dynamic/Inventory%20Optimization_Icon-01.png" alt="" />

                            <h4>Customized Migration Plans </h4>
                            <p>
                            Tailored brownfield, greenfield, or hybrid strategies ensure seamless SAP S/4HANA integration with minimal disruption. 
                            </p>
                        </div>
                        </Col>
                        <Col lg={4} md={6} sm={10}>
                        <div className='sap-roadmap'>
                        <img loading="lazy" className='' src="https://piloggroup.com/industries/img/dynamic/Inventory%20Optimization_Icon-01.png" alt="" />

                            <h4>Real-Time Monitoring & Support </h4>
                            <p>Live dashboards and automated validation ensure data integrity, system performance, and compliance. </p>
                        </div>
                        </Col>
                        <Col lg={4} md={6} sm={10}>
                        <div className='sap-roadmap'>
                        <img loading="lazy" className='' src="https://piloggroup.com/industries/img/dynamic/Inventory%20Optimization_Icon-01.png" alt="" />
                            
                            <h4>Training & Empowerment for Your Team </h4>
                            <p>
                            Hands-on training and workshops equip teams for efficient SAP S/4HANA adoption and usage. 
                            </p>
                        </div>
                        </Col>
                        <Col lg={4} md={6} sm={10}>
                        <div className='sap-roadmap'>
                        <img loading="lazy" className='' src="https://piloggroup.com/industries/img/dynamic/Inventory%20Optimization_Icon-01.png" alt="" />

                            <h4>Post-Migration Optimization </h4>
                            <p>
                            Audits, performance tuning, and governance improvements maximize SAP S/4HANA efficiency and business growth.
                            </p>
                        </div>
                        </Col>
                    </Row>
                </Container>
            </section>


            <section class="industry-leaders-section">
                <div class="container">
                    <div class="row justify-content-center align-items-center">

                        <div class="col-lg-12">

                            <div class="industry-leaders">
                                <div class="empty-div"></div>
                                <h2>Total Business Value You Can’t Ignore in Data Migration! ​</h2>

                            </div>
                            <div class=" industry-leaders-achivements">
                                <div class="row d-flex justify-content-center align-items-center">

                                    <div class="col-md-12 the-result">

                                        <div class="row d-flex">
                                            <div class="col-md-6 col-sm-10 col-lg-3 results">
                                                <h3>10X </h3>
                                                <p>Effort/Cost Reduction</p>
                                            </div>
                                            <div class="col-md-6 col-sm-10 col-lg-3 results">
                                                <h3>10X</h3>
                                                <p>Faster Implementation </p>
                                            </div>
                                            <div class="col-md-6 col-sm-10 col-lg-3 results">
                                                <h3>5X</h3>
                                                <p>Acceleration in Time-to-Value (TTV) </p>
                                            </div>
                                            <div class="col-md-6 col-sm-10 col-lg-3 results">
                                                <h3>100%</h3>
                                                <p>Data Qality Improvement</p>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </section>

            <section className='sap-cta'>
        <div >
          <Container className='sap-cta-content'>
            
            <h2>Migrate with Confidence—Faster, Smarter, Better!</h2>
            <p>
            Want to see iContent Foundry in action, get a free data assessment, or talk with a data migration expert?
            </p>
            <Link to="/extended-supply">
                  <Button variant="filled" rightSection={<ArrowOutwardIcon fontSize="20`"/>}>Contact US Today </Button>
                </Link>
           
           
          </Container>
        </div>
      </section>
        </div>
    )
}
