// These are some constant exports that are useful for code reduction
// File created by Samiuddin khan
import SalomonImg from '../Components/Assets/leadership/Salomon-Jager-large.jpg';
import PieterImg from '../Components/Assets/leadership/pieter-strydom.jpg';
import GideonImg from '../Components/Assets/leadership/gideon.jpg';
import ImadImg from '../Components/Assets/leadership/Imad.jpg';
import WynandImg from '../Components/Assets/leadership/Wynand-Nortje.png';
import TracyImg from '../Components/Assets/leadership/Bennett.jpg';
import ReinhardImg from '../Components/Assets/leadership/Reinhard.jpg';
import JohanImg from '../Components/Assets/leadership/johan-zwarts.jpg';
import DataScienceIcon from '../Components/Assets/LDG/LDG-ICONS/LDG-ICONS-06.png'
import GenAiICon from '../Components/Assets/LDG/LDG-ICONS/LDG-ICONS-07.png'
import BusinessIcon from '../Components/Assets/LDG/LDG-ICONS/LDG-ICONS-08.png'
import IndustryIcon from '../Components/Assets/LDG/LDG-ICONS/LDG-ICONS-09.png'
import SecurityIcon from '../Components/Assets/LDG/LDG-ICONS/LDG-ICONS-10.png'


// IDQM page section constants starts here 

export const dataQualityArray = [
    "Analyze the source data content for completeness, consistency, redundancy, standardization, richness, etc",
    "Auto Assignment of Class & Characteristics from the PiLog's Taxonomy to each record",
    "Extract the characteristic values & UOM's from the source descriptions for each record",
    "Extract reference data from the source descriptions such as Part#/Model#/Drawing#/Mnfr/Vendor etc for each record",
    "Bulk review of materials (QC Tools & DQ Assessment)",
    "Auto mapping of source data with PiLog repositories & other reliable sources",
    "Assign the data sets to relevant user groups based on various criteria",
    "Capture additional information (or) Validate processed/structured datd",
    "Provision to collect the field data & update (Physical Walk down)",
    "Auto-generation of Short & PO text based on User configure",
    "Identification of redundant records",
    "Export the data to be migrated to a Target system(s)",
    "Integrate in real-time with other systems",
    "Data Quality assessment & progress reports",
]


export const sparesDataArray = [
    "PiLog's objective is to have a global repository so that no item requires manual cataloging.",
    "PiLog has developed PPR, a repository/central location in which data is stored and managed.",
    "PPR has embedded industry-standard content and ISO-compliant processes (Data exchange compliant to ISO 29002).",
    "PiLog has spent over twenty years researching, developing, and refining the PPR (PiLog Preferred Records).",
    "For those wanting to start with structured descriptions now without waiting for historical data cleanup, we offer the Structured Text Generator to build class-driven descriptions that improve search capability and eliminate free-text spending.",
    "PPR is not only limited to materials but also has service master domain details, i.e., 0.5 million readily structured service master records are available.",
    "PPR has 100% accuracy in the descriptions.",
    "Re-usability of the golden records as they are independent of 'language,' 'region,' and 'industry sector'."
]

export const taxonomyArr1 = [
    "The class and characteristics combination for a particular class, including linkage to respective United Nations Standard Products and Services Code version 21.",
    "The characteristics listed on the template as complete, and properly identified as mandatory and optional.",
    "The proposed abbreviations.",
    "Ordering of properties for description generation."
]

export const taxonomyArr2 = [
    "Change existing classes and/or characteristics of the CPO where necessary.",
    "Register new classes and/or characteristics in the OTD, and add them to the CPO.",
    "If changes are made to the CPO dictionary, PiLog will only update the changes/additions in the MDPM software tool; no additional approval is required from the Client to incorporate the changes, as PiLog manages the CPO dictionary according to industry standards.",
    "The CPO dictionary is the intellectual property of PiLog. In no way may it be edited, copied, compared, mapped, transmitted, imported/exported into other software/systems, or printed/published without prior written permission of PiLog. CPO includes concepts, classes, terms, definitions, languages, abbreviations, data requirements, equivalences, images, data types, translations, and any data structures or relationships of the content stored within the CPO."
]

export const dataCataLogRules = [
    "Do not remove or delete any data provided by the client unless the data is duplicated. 'Duplicated' in this context refers to a scenario where a word, concept, value, attribute, etc., is duplicated within a single description or text provided for an item.",
    "Records are never deleted by PiLog but will be flagged as potential duplicates. It is the client’s responsibility to verify and confirm whether items flagged as potential duplicates are indeed duplicates before removing them from the item master set.",
    "Do not add extra values to client data unless researched from a source with integrity and authority. If PiLog adds values to a client’s master data item, PiLog provides the source and authenticity for the added data.",
    "If descriptions are incomplete, incorrect, or contain conflicting information, query the client before assigning a class or values. PiLog does not assign a class if the source description or information provided by the client is unclear. PiLog seeks additional information or a decision from the client; records with pending queries are kept on hold until the query is resolved.",
    "Electronic Data Verification (EDV) is the process whereby the source data received from the client is processed into the cataloging system via the EOTD dictionary, where the correct item name and approved attribute template is linked, and the data for the material item is populated into the template.",
    "Descriptions are then generated according to certain rules. There are different levels of cataloging."
];



// IDQM page section constants starts here 


// leader ship contants here 


export const leadershipArray = [
    {
        "id": "PLG-001",
        "name": "Dr. Salomon De Jager",
        "position": "Chairman",
        "company": "PiLog Group",
        "linkedin": true,
        "twitter": true,
        "description": "Dr Salomon de Jager completed his Hons.B-Eng. degree (Mech. Ind.) in 1978.",
        "image": SalomonImg
    },
    {
        "id": "PLG-002",
        "name": "Pieter Strydom",
        "position": "Co - Chief Executive Officer",
        "company": "PiLog Group",
        "linkedin": true,
        "twitter": true,
        "description": "Mr Strydom has a Mechanical Engineering degree from the University of Pretoria, South Africa",
        "image": PieterImg
    },
    {
        "id": "PLG-003",
        "name": "Gideon Huisamen",
        "position": "Co - Chief Executive Officer",
        "company": "PiLog Group",
        "linkedin": true,
        "twitter": true,
        "description": "Mr Gideon Huisamen completed his National Higher Diploma in Cost Accounting",
        "image": GideonImg
    },
    {
        "id": "PLG-004",
        "name": "Dr. Imad Syed",
        "position": "Co - Chief Executive Officer & Chief Information Officer",
        "company": "PiLog Group",
        "linkedin": true,
        "twitter": true,
        "description": "Senior Executive, Digital Advisory, Visionary Leader with vast experience in conceiving & designing business",
        "image": ImadImg
    },
    {
        "id": "PLG-005",
        "name": "Wynand Nortje",
        "position": "Chief Technology Officer",
        "company": "PiLog Group",
        "linkedin": true,
        "twitter": true,
        "description": "Mr Wynand Nortje obtained his Industrial Engineering degree from the University",
        "image": WynandImg
    },
    {
        "id": "PLG-006",
        "name": "Tracy Bennett",
        "position": "Chief Operating Officer",
        "company": "PiLog Group",
        "region": "Americas and Canada - Zone1",
        "linkedin": true,
        "twitter": true,
        "description": "Tracy Bennett has 31 years of experience in leading the organizations",
        "image": TracyImg
    },
    {
        "id": "PLG-007",
        "name": "Reinhard Schiel",
        "position": "Chief Operating Officer",
        "company": "PiLog Group",
        "region": "Europe and CIS - Zone2",
        "linkedin": true,
        "twitter": true,
        "description": "He started his career as a telecommunications engineer in the laboratories of Telkom",
        "image": ReinhardImg
    },
    {
        "id": "PLG-008",
        "name": "Johan Zwarts",
        "position": "Chief Operating Officer",
        "company": "PiLog Group",
        "region": "Africa - Zone2",
        "linkedin": true,
        "twitter": true,
        "description": "Johan is the Chief Operating Officer and Senior Program Manager at PiLog Africa",
        "image": JohanImg
    }
];

// Json content for LDG page

export const ldgAccordian = [
    {
        "icon": DataScienceIcon,
        "title": "Data Science",
        "description": "Data Science involves extracting insights and knowledge from data using techniques from statistics, computer science, and domain expertise. It encompasses data collection, processing, analysis, and visualization to support decision making and predictive modeling."
    },
    {
        "icon": GenAiICon,
        "title": "Generative & Augmented AI",
        "description": "Generative AI creates new content or data using machine learning models, such as text, images, or music. Augmented AI enhances human capabilities by combining AI's strengths with human intelligence for improved decision making and productivity."
    },
    {
        "icon": BusinessIcon,
        "title": "Business & Data KPIs & KRAs",
        "description": "Data Science involves extracting insights and knowledge from data using techniques from statistics, computer science, and domain expertise. It encompasses data collection, processing, analysis, and visualization to support decision making and predictive modeling."
    },
    {
        "icon": IndustryIcon,
        "title": "Industry Standards, Policies, Procedures",
        "description": "Data Science involves extracting insights and knowledge from data using techniques from statistics, computer science, and domain expertise. It encompasses data collection, processing, analysis, and visualization to support decision making and predictive modeling."
    },
    {
        "icon": SecurityIcon,
        "title": "Security, Risk Compliance & Regulations",
        "description": "Data Science involves extracting insights and knowledge from data using techniques from statistics, computer science, and domain expertise. It encompasses data collection, processing, analysis, and visualization to support decision making and predictive modeling."
    }
]

//Industry and customer 

export const clients = [
    "Airport and Aviation",
    "Cement",
    "Chemicals and Refineries",
    "Construction",
    "Defence",
    "Education",
    "Energy and Utilities",
    "Food and Beverages",
    "Hospitality",
    "Iron and Steel",
    "Manufacture",
    "Marine",
    "Mining",
    "Oil and Gas",
    "Telecommunications",
    "Textile",
    "Transport"
]

